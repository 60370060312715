<template>
<div>
    <div class="headerLegacy text-center">
        <p class="neutralGrey f-15">{{$t('head.text1')}} <router-link :to="{name : 'introducingZuluTrade'}">{{$t('login.here')}} </router-link>. {{$t('login.existing')}} <a :href="static_vars.OldWebsiteURL" target="_blank"> {{$t('login.here')}}</a> </p>
    </div>
    <header class="mainHeader border-bottom" id="myHeader">
        <nav class="navbar navbar-expand-lg">
                <div class="container-fluid">
                    <!-- <router-link class="lightTheme navbar-brand d-flex p-0" to="/"><img width="180" height="40" src="/assets/images/logo.svg" :alt="$t('head.text21')" :title="$t('header.zulu_trade')" /></router-link>
                    <router-link class="darkTheme navbar-brand d-none p-0" to="/"><img width="180" height="40" src="/assets/images/whiteLogo.svg" :alt="$t('head.text21')" :title="$t('header.zulu_trade')" /></router-link> -->
                    <a :href="`${static_vars.domainURL}`" class="lightTheme navbar-brand d-flex p-0" ><img width="180" height="40" src="/assets/images/logo.svg" :alt="$t('head.text21')" :title="$t('header.zulu_trade')" /></a>
                    <a :href="`${static_vars.domainURL}`" class="darkTheme navbar-brand d-none p-0" ><img width="180" height="40" src="/assets/images/whiteLogo.svg" :alt="$t('head.text21')" :title="$t('header.zulu_trade')" /></a>
                    <!-- <button @click="addClass('')" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar"><span class="navbar-toggler-icon"></span></button> -->
                    <div class="collapse navbar-collapse" id="mynavbar">
                        <ul class="megaMenuList navbar-nav me-auto align-items-center">
                            <li class="nav-item d-lg-none" @click="addClass('remove')">
                                <router-link class="nav-link menuTitle home" to="/">{{$t('header.home')}}</router-link>
                            </li>
                            <li class="nav-item" @click="addClass('remove')">
                                <router-link class="nav-link menuTitle leaders" to="/leaders">{{$t('header.leader')}}</router-link>
                            </li>
                            <li class="nav-item" @click="addClass('remove')">
                                <router-link class="nav-link menuTitle markets" to="/markets">{{$t('header.market')}}</router-link>
                            </li>
                            <li class="nav-item hideTabMobile">
                                <a class="nav-link dropdown-toggle menuTitle" role="button" data-bs-toggle="dropdown"
                                    href="javascript:void(0)">{{$t('header.tool')}}</a>
                                <div class="dropdown-menu w-100 showMegaMenu" @click="addClass('remove')">
                                    <div class="container max-970">
                                        <div class="row justify-content-center">
                                            <div class="col-12 col-lg-4">
                                                <div class="d-flex align-items-start horizontralBorder">
                                                    <span class="icon"><v-lazy-image src="/assets/images/tools.svg"
                                                        :alt="$t('head.text23')" :title="$t('header.title2')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.trading')}} </h5>
                                                        <p class="mb-0 medium line-4 f-13 gray">{{$t('header.use')}}</p>
                                                        <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                            <li><router-link class="px-0 dropdown-item" to="/simulation"> {{$t('header.text1')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/calendar"> {{$t('header.text2')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" :to="{name:'calculator'}"> {{$t('header.text3')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" :to="{path:'/pricing'}"> {{$t('header.text4')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" :to="{path:'/autoprotect-your-account'}"> {{$t('footer.text9')}} </router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <div class="d-flex align-items-start horizontralBorder">
                                                    <span class="icon"><v-lazy-image src="/assets/images/blog.svg"
                                                        :alt="$t('header.title14')" :title="$t('header.title1')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.blog')}} </h5>
                                                        <p class="mb-0 medium line-4 f-13 gray">{{$t('header.check_out')}}</p>
                                                        <div class="d-flex align-items-start justify-content-between blogHeader">
                                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0" v-if="static_vars.BlogsArray.length">
                                                                <!-- <li><router-link  class="px-0 dropdown-item"
                                                                    :to="{name : 'marketNews'}">Market
                                                                        News</router-link></li>
                                                                <li><a class="px-0 dropdown-item"
                                                                        href="https://blog.zulutrade.com/category/Knowledge-Crunch">Knowledge
                                                                        Crunch</a></li>
                                                                <li><a class="px-0 dropdown-item"
                                                                        href="https://blog.zulutrade.com/category/New-Features">New
                                                                        Features</a></li>
                                                                <li><a class="px-0 dropdown-item"  href="https://blog.zulutrade.com/category/Trading-Floor">Trading Floor</a></li> -->
                                                                <li v-for="list in static_vars.BlogsArray" :key="list.id" @click="addClass('remove')"><router-link  class="px-0 dropdown-item"
                                                                    :to="{name : 'AllBlogs',params:{'name' : list.slug}}">{{list.name}}</router-link></li>
                                                            </ul>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <div class="d-flex align-items-start">
                                                    <span class="icon"><v-lazy-image src="/assets/images/instruments.svg"
                                                        :alt="$t('head.text23')" :title="$t('header.title2')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.Knowledgebase')}} </h5>
                                                        <p class="mb-0 medium line-4 f-13 gray">{{$t('header.use')}}</p>
                                                        <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                            <!-- <li><router-link class="px-0 dropdown-item" to="/help-center">{{$t('header.help')}}</router-link></li> -->
                                                            <li><router-link class="px-0 dropdown-item" to="/user-guide">{{$t('header.guide')}}</router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/leader-guide">{{$t('header.leader_guide')}} </router-link></li>
                                                            <!-- <li><router-link class="px-0 dropdown-item" to="/affiliate-guide">{{$t('header.affiliate')}} </router-link></li> -->
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item" @click="addClass('remove')"><router-link class="nav-link menuTitle community" to="/social-feed"> {{$t('header.community')}} </router-link> </li>
                            <li class="nav-item hideTabMobile">
                                <a class="nav-link dropdown-toggle menuTitle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown">{{$t('header.about')}}</a>
                                <div class="dropdown-menu w-100 showMegaMenu" @click="addClass('remove')">
                                    <div class="container max-970">
                                        <div class="row justify-content-center">
                                            <div class="col-12 col-lg-5">
                                                <div class="d-flex align-items-start horizontralBorder">
                                                    <span class="icon"><v-lazy-image src="/assets/images/zulu.svg" :alt="$t('header.title16')" :title="$t('header.title19')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.about_us')}} </h5>
                                                        <p class="mb-0 medium line-4 f-13 gray">{{$t('header.about_title')}}</p>
                                                        <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                            <li><router-link class="px-0 dropdown-item" to="/about"> {{$t('header.company')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/news-and-media">{{$t('header.media')}} </router-link></li>
                                                            <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/support">{{$t('header.support')}} </router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-5">
                                                <div class="d-flex align-items-start">
                                                    <span class="icon"><v-lazy-image src="/assets/images/partnerships.svg" :alt="$t('header.title17')" :title="$t('header.title20')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.partner')}}</h5>
                                                        <p class="mb-0 medium f-13 gray">{{$t('header.partner_title')}}</p>
                                                        <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                            <li><router-link class="px-0 dropdown-item" to="/become-an-affiliate">{{$t('header.program1')}}</router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/become-a-leader">{{$t('header.program2')}}</router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/partnership-program">{{$t('header.program3')}}</router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item hideTabMobile" @click="addClass('remove')">
                                <router-link class="nav-link menuTitle markets" to="/help-center">{{$t('sitemap.sitemapcontent18')}}</router-link>
                            </li>
                            <!-- Only Show for mobile -->
                            <li class="d-lg-none nav-item d-flex align-items-center clickOutRemove">
                                <a href="javascript:void(0)" class="more nav-link menuTitle" :class="showHumbugerMenu?'active':''" @click="showHumbugerMenu = !showHumbugerMenu"> {{$t('header.more')}} </a> 
                                <ul class="megaMenusubList pb-0" :class="[{'viewmoreMenu':showHumbugerMenu}]">
                                    <li class="closemenuList text-end position-fixed"><a class="secondary d-inline-flex" href="javascript:void(0)" @click="showHumbugerMenu = false"><vue-feather type="x-circle"></vue-feather></a></li>
                                    <li class="nav-item viewMobileListed">
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.Knowledgebase')}} </h5>
                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                <!-- <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/help-center">{{$t('header.help')}}</router-link></li> -->
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/user-guide">{{$t('header.guide')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/leader-guide">{{$t('header.leader_guide')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/affiliate-guide">{{$t('header.affiliate')}} </router-link></li>
                                            </ul>
                                        </div>
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.blog')}} </h5>
                                            <div class="d-flex align-items-start justify-content-between blogHeader">
                                                <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0" v-if="static_vars.BlogsArray.length">
                                                    <!-- <li><router-link  class="px-0 dropdown-item"
                                                        :to="{name : 'marketNews'}">Market
                                                            News</router-link></li>
                                                    <li><a class="px-0 dropdown-item"
                                                            href="https://blog.zulutrade.com/category/Knowledge-Crunch">Knowledge
                                                            Crunch</a></li>
                                                    <li><a class="px-0 dropdown-item"
                                                            href="https://blog.zulutrade.com/category/New-Features">New
                                                            Features</a></li>
                                                    <li><a class="px-0 dropdown-item"  href="https://blog.zulutrade.com/category/Trading-Floor">Trading Floor</a></li> -->
                                                    <li v-for="list in static_vars.BlogsArray" :key="list.id" @click="addClass('remove')"><router-link  class="px-0 dropdown-item"
                                                        :to="{name : 'AllBlogs',params:{'name' : list.slug}}">{{list.name}}</router-link></li>
                                                </ul>
                                                
                                            </div>
                                        </div>
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.trading')}} </h5>
                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/simulation"> {{$t('header.text1')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/calendar"> {{$t('header.text2')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" :to="{name:'calculator'}"> {{$t('header.text3')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" :to="{path:'/pricing'}">{{$t('header.text4')}} </router-link></li>
                                            </ul>
                                        </div>
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.about_us')}} </h5>
                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/about"> {{$t('header.company')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/news-and-media">{{$t('header.media')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/support">{{$t('header.support')}} </router-link></li>
                                            </ul>
                                        </div>
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.partner')}}</h5>
                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/become-an-affiliate">{{$t('header.program1')}}</router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/become-a-leader">{{$t('header.program2')}}</router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/partnership-program">{{$t('header.program3')}}</router-link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="rightHeader">
                        <ul class="loginRegister d-flex align-items-center mb-0">
                            <li class="d-flex align-items-center me-3">
                                <router-link class="linkBtn mobLogin d-flex align-items-center me-4" to="/login"><vue-feather class="me-1" size="16" type="log-in"></vue-feather> <span>{{$t('login.button')}}</span></router-link> 
                                <a :href="static_vars.domainURL+'register/'" class="button mobLogin d-flex align-items-center fillBtn linkBtn"> <vue-feather class="me-1" size="16" type="user-plus"></vue-feather> <span>{{$t('header.register')}}</span></a>
                            </li>
                            <li class="nav-item">
                                <a id="theme-toggle" class="d-flex" :title="$t('header.mode_change')" :class="[{'light':currentTheme=='light'},{'dark':currentTheme=='dark'}]" href="javascript:void(0)" @click="changeTheme()">
                                    <vue-feather class="me-1" size="20" type="moon"  v-if="currentTheme=='light'"></vue-feather> 
                                    <vue-feather class="me-1" size="20" type="sun" v-if="currentTheme=='dark'"></vue-feather> 
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
    </header>
</div>
</template>

<script>
// import $ from "jquery";
export default {
    data() {
        return {
            siteUrl: process.env.VUE_APP_CLIENT_PORTAL_URL,
            registerurl: process.env.VUE_APP_CLIENT_PORTAL_REGISTER_URL,
            currentTheme: '',
            showHumbugerMenu: false,
        };
    },
    // mounted() {
    //     $('.selectDropdown').click(function(){
    //         $('.dropdown_menu_animated').toggleClass('show');
    //     });
    // },
    methods: {
        changeTheme(){
            if(this.currentTheme=='light'){
                this.currentTheme = 'dark'
            } else {
                this.currentTheme = 'light'
            }
            // this.static_vars.getThemeMode(this.currentTheme)
        },
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === "addClass") {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        addClass(getData) {
            const el = document.body;
            if (getData == "remove") {
                if (el.classList.contains("toggle")) {
                el.classList.remove("toggle");
                }
            } else {
                if (el.classList.contains("toggle")) {
                el.classList.remove("toggle");
                } else {
                el.classList.add("toggle");
                }
            }
            this.showHumbugerMenu = false
        },
    },
    mounted(){
        var header = document.getElementById("myHeader");
            window.addEventListener("scroll", () => {
            if (window.pageYOffset > header.offsetTop) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        });
        var toggle = document.getElementById("theme-toggle");
        var storedTheme = localStorage.getItem('theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
        if (storedTheme) {
            document.documentElement.setAttribute('data-theme', storedTheme)
        }
        if(toggle){
            toggle.onclick = function() {
                var currentTheme = document.documentElement.getAttribute("data-theme");
                var targetTheme = "light";
                if (currentTheme === "light") {
                    targetTheme = "dark";
                }
                document.documentElement.setAttribute('data-theme', targetTheme)
                localStorage.setItem('theme', targetTheme);
            };
        }
        this.currentTheme = localStorage.getItem('theme')
    },
};
</script>
