import { createRouter, createWebHistory } from "vue-router";
import { myStore } from "@/store/pinia-store.ts";
import static_vars from '@/staticVars'
//Entry point
import Index from "@/views/index.vue";
import seoData from '../../public/assets/js/seo.js'
import {nextTick} from 'vue';

const routes = [
    {
        path: "/mobile-faqs",
        name: "moble faqs",
        component: () => import("@/views/mobile/mobile_faq.vue"),
        requiresAuth: false,
        beforeEnter: customCheckForPhoneCountryCase, 
    },
    {
        path: "/mobile-risk-disclaimer",
        name: "mobile risk disclaimer",
        component: () => import("@/views/mobile/mobile-risk-disclaimer.vue"),
        requiresAuth: false,
    },
    // {
    //     path: "/payment/redirect",
    //     name: "redirect",
    //     component: () => import("@/views/dashboard-new/pages/redirect/redirect.vue"),
    //     requiresAuth: false,
    // },
    // {
    //     path: "/payment/in-process",
    //     name: "inProcess",
    //     component: () => import("@/views/dashboard-new/pages/redirect/inProcess.vue"),
    //     requiresAuth: false,
    // },
    // {
    //     path: "/mobile-redirect/",
    //     name: "mobileRedirect",
    //     component: () => import("@/views/mobile/mobile-redirect.vue"),
    //     requiresAuth: false,
    // },
    {
        path: "/widgets/:widget",
        name: "widgets",
        component: () => import("@/views/widgets/index.vue"),
        requiresAuth: false,
        beforeEnter: customCheckForPhoneCountryCase, 
    },
    {
        path: "/widgets/banner/:type",
        name: "banner",
        component: () => import("@/views/widgets/banner.vue"),
        requiresAuth: false,
        beforeEnter: customCheckForPhoneCountryCase, 
    },
    {
        path: "/widgets/trader-info/:id",
        name: "traderInfo",
        component: () => import("@/views/widgets/trader-info.vue"),
        requiresAuth: false,
        beforeEnter: customCheckForPhoneCountryCase, 
    },
    {
        path: "/chart/:id",
        name: "chart",
        component: () => import("@/views/trader/chart.vue"),
        requiresAuth: false,
        beforeEnter: customCheckForPhoneCountryCase, 
    },
    {
        path: "/advanced/:id",
        name: "advanced",
        component: () => import("@/views/trader/advanced.vue"),
        requiresAuth: false,
        beforeEnter: customCheckForPhoneCountryCase, 
    },
    {
        path: "/market/:id",
        name: "mobileMarket",
        redirect: {name: 'rates'},
        component: () => import("@/views/markets/mobile-market/market-chart.vue"),
        requiresAuth: false,
        beforeEnter: customCheckForPhoneCountryCase, 
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/login.vue"),
        requiresAuth: false,
    },
    {
        path: "/widgets/register",
        name: "register",
        component: () => import("@/views/auth/register.vue"),
        requiresAuth: false,
    }, 
    {
        path: "/widgets/performance",
        name: "Widgetperformance",
        component: () => import("@/views/widgets/performance.vue"),
        requiresAuth: false,
        beforeEnter: customCheckForPhoneCountryCase, 
    },
    { path: "/sign-up-investor", },
    { path: "/sign-up-trader-program", },
    { path: "/create-account", },
    { path: "/forgot-password" },
    { path: "/update-password" },
    { path: "/simulate" },
    { path: "/zulutrade-gateway" },
    { path: "/zulutrade-client" },
    { path: "/sign-up-affiliate-program" },
    { path: "/traders/winning/30" },
    { path: "/tradewall" },
    { path: "/&quot" },
    { path: "/index" },
    { path: "/index.2001" },
    { path: "/Index.asp" },
    { path: "/Index.aspx" },
    { path: "/index3" },
    { path: "/UserGuide.aspx" },
    { path: "/charts" },
    { path: "/list/help-center-questions" },
    { path: "/signup-intermediate" },
    { path: "/signup" },
    {
        path: "/:catchAll(.*)",
        name: "notFound",
        component: () => import("@/components/shared/page-not-found.vue"),
        beforeEnter: (to, from, next) => {
            redirectCorrectRoute(to, from, next)
        },
        requiresAuth: false,
    },
    {
        path: "/",
        component: Index,
        meta: {
            requiresAuth: true,
        },

        children: [
            {
                path: "/",
                name: "index",
                component: () => import("@/views/home/home.vue"),
                requiresAuth: false,
                
            },
            {
                path: "/about",
                name: "about",
                component: () => import("@/views/about/about.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 
            },
            {
                path: "/become-an-affiliate",
                name: "affiliate",
                component: () => import("@/views/about/become-an-affiliate.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/affiliate-guide",
                name: "affiliateGuide",
                component: () => import("@/views/about/affiliate-guide.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/introducing-zulutrade",
                name: "introducingZuluTrade",
                component: () => import("@/views/about/introducing-zulutrade.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/leader-guide",
                name: "traderGuide",
                component: () => import("@/views/about/leader-guide.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/become-a-leader",
                name: "leader",
                component: () => import("@/views/about/become-a-leader"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/partnership-program",
                name: "partnership",
                component: () => import("@/views/about/partnership-program.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/zulutrade-data",
                name: "Zulutrade Data",
                component: () => import("@/views/about/zulutrade-data.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/user-guide",
                name: "userGuideTrade",
                component: () => import("@/views/about/user-guide.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/help-center",
                name: "help center",
                component: () => import("@/views/learn/help-center.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/pricing",
                name: "pricingPage",
                component: () => import("@/views/pricing/pricing.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/feedback",
                name: "feedBack",
                component: () => import("@/views/about/feedback.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/markets",
                name: "rates",
                component: () => import("@/views/markets/markets.vue"),
                requiresAuth: false,
                props: true,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/market-details/:id/:currency",
                name: "market_details",
                redirect: {name: 'rates'},
                component: () => import("@/views/markets/market_details.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/calendar",
                name: "calendar",
                component: () => import("@/views/markets/calendar.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/autoprotect-your-account",
                name: "autoprotectAccount",
                component: () => import("@/views/features/autoprotect-your-account.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/trader/:id/trading",
                name: "traderDetail",
                component: () => import("@/views/trader/trader-detail.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/leaders",
                name: "traders",
                component: () => import("@/views/trader/top-traders.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase,
            },
            {
                path: "/simulation",
                name: "simulation",
                component: () => import("@/views/simulation/simulation.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/social-feed",
                name: "socialFeed",
                component: () => import("@/views/social-feed/social-feed.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/newsfeed/share",
                name: "socialFeedDetail",
                component: () => import("@/views/social-feed/feed-detail.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/news-and-media",
                name: "newsAndMedia",
                component: () => import("@/views/news/news-and-media.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/compare",
                name: "Compare",
                component: () => import("@/views/trader/compare/compare.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/disclaimer",
                name: "disclaimer",
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/help-center-questions",
                name: "help center questions",
                component: () => import("@/views/learn/help-center-questions.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/terms-of-service",
                name: "terms of service",
                component: () => import("@/views/terms/terms-of-service.vue"),
                requiresAuth: false,
            },
            {
                path: "/conflict-of-interest-policy",
                name: "conflict_of_intrest_policy",
                component: () => import("@/views/terms/conflict-of-intrest-policy.vue"),
                requiresAuth: false,

            },
            {
                path: "/declaration-of-consent",
                name: "declaration_of_consent",
                component: () => import("@/views/terms/declaration-of-consent.vue"),
                requiresAuth: false,

            },
            {
                path: "/eula",
                name: "eula",
                component: () => import("@/views/terms/eula.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/privacy-policy",
                name: "privacy policy",
                component: () => import("@/views/terms/privacy.vue"),
                requiresAuth: false,

            },
            {
                path: "/cookies-policy",
                name: "cookiesPolicy",
                component: () => import("@/views/terms/cookies-policy.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/zuluranking",
                name: "zuluranking",
                component: () => import("@/views/terms/zuluranking.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/risk-disclaimer",
                name: "risk disclaimer",
                component: () => import("@/views/terms/risk-disclaimer.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/terms-of-service-eu",
                name: "terms of service eu",
                component: () => import("@/views/terms/terms-of-service-eu.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/copy-trading",
                name: "copy trading",
                component: () => import("@/views/trader/trading/copy_trading.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 
                
            },
            {
                path: "/open-new-investror-account",
                name: "open new investror account",
                component: () => import("@/views/investment/open-new-investror-account.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/printable-terms",
                name: "printable terms",
                component: () => import("@/views/terms/printable-terms.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/select-broker",
                name: "select broker",
                component: () => import("@/views/broker/select-broker.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/social-trading",
                name: "social trading",
                component: () => import("@/views/social-feed/social-trading.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/traders/list/:id",
                name: "view_all_leader",
                component: () => import("@/views/trader/view_all_leader.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/all-market/:currency",
                name: "all_market",
                component: () => import("@/views/markets/all-market.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            // {
            //     path: "/payment/success",
            //     name: "success",
            //     component: () => import("@/views/dashboard-new/pages/redirect/success.vue"),
            //     requiresAuth: true,
            //     beforeEnter: customCheckForPhoneCountryCase, 

                
            // },

            // {
            //     path: "/payment/failure",
            //     name: "failure",
            //     component: () => import("@/views/dashboard-new/pages/redirect/failure.vue"),
            //     requiresAuth: true,
            //     beforeEnter: customCheckForPhoneCountryCase, 

            // },
            {
                path: "/rewards",
                name: "rewards",
                component: () => import("@/views/dashboard-new/pages/rewards.vue"),
                requiresAuth: true,
                beforeEnter: customCheckForPhoneCountryCase, 
            },
            {
                path: "/notifications",
                name: "allNotifications",
                component: () => import("@/views/notifications/all-notifications.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/watchlist",
                name: "watchlist",
                component: () => import("@/views/trader/watchlist/watchlist.vue"),
                requiresAuth: true,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/dashboard",
                name: "dashboard_portfolio",
                component: () => import("@/views/dashboard-new/dashboard_portfolio.vue"),
                requiresAuth: true,
                beforeEnter: customCheckForPhoneCountryCase, 
            },
            {
                path: "/zuluguard-guide",
                name: "zuluguardGuide",
                component: () => import("@/views/zuluguard/zuluguard.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/delete-account",
                name: "deleteAccount",
                component: () => import("@/views/about/delete_account.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 
            },
            {
                path: "/trading-tools/pip-calculator",
                name: "pipCalculator",
                component: () => import("@/views/markets/calculator/pip.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/trading-tools/margin-calculator",
                name: "marginCalculator",
                component: () => import("@/views/markets/calculator/margin.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/trading-tools/profit-calculator",
                name: "profitCalculator",
                component: () => import("@/views/markets/calculator/profit.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/trading-tools/currency-converter",
                name: "currencyCalculator",
                component: () => import("@/views/markets/calculator/currency.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/trading-tools",
                name: "calculator",
                component: () => import("@/views/markets/forex-tools.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            // New Funded
            {
                path: "/funded-account/funded-account",
                name: "fundedAccount",
                component: () => import("@/views/funded-account/funded-account.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "",
                name: "dashboardTabs",
                component: () => import("@/views/dashboard-new/dashboard.vue"),
                children: [
                    {
                        path: "/dashboard/personal-info",
                        name: "dashboardinfo",
                        component: () => import("@/views/dashboard-new/pages/personal-detail.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 
                    },
                    {
                        path: "/dashboard/trading-accounts",
                        name: "tradingAccounts",
                        component: () => import("@/views/dashboard-new/pages/trading-accounts.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 

                    },
                    // {
                    //     path: "/dashboard/subscription",
                    //     name: "subscription",
                    //     component: () => import("@/views/dashboard-new/pages/subscription-plan.vue"),
                    //     requiresAuth: true,
                    //     beforeEnter: customCheckForPhoneCountryCase, 
                    // },
                    {
                        path: "/dashboard/account",
                        name: "accountSetting",
                        component: () => import("@/views/dashboard-new/pages/account-settings.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 
                    },
                    {
                        path: "/dashboard/need-help",
                        name: "needHelp",
                        component: () => import("@/views/dashboard-new/pages/need-help.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 
                    },
                    {
                        path: "/dashboard/my-posts",
                        name: "myPosts",
                        component: () => import("@/views/dashboard-new/pages/my-feed.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 
                    },
                    {
                        path: "/dashboard/change-password",
                        name: "changePassword",
                        component: () => import("@/views/dashboard-new/pages/change-password.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 
                    },
                    {
                        path: "/dashboard/leader-widget",
                        name: "leaderWidget",
                        component: () => import("@/views/dashboard-new/pages/leader-widget.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 
                    },
                    {
                        path: "/dashboard/notifications",
                        name: "notificationSetting",
                        component: () => import("@/views/dashboard-new/pages/settings.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 
                    },
                    {
                        path: "/dashboard/trade-history",
                        name: "tradeHistory",
                        component: () => import("@/views/dashboard-new/pages/trade-history.vue"),
                        requiresAuth: true,
                        beforeEnter: customCheckForPhoneCountryCase, 
                    },
                ],
            },
            {
                path: "/blog/category/:name",
                name: "AllBlogs",
                component: () => import("@/views/blog/blog-category.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/blog/post/:title",
                name: "blogDetail",
                component: () => import("@/views/blog/blog-detail.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/sitemap",
                name: "sitemap",
                component: () => import("@/views/learn/sitemap.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/welcome-a",
                name: "welcomeA",
                component: () => import("@/views/welcomepage/welcome-a.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/welcome-b",
                name: "welcomeB",
                component: () => import("@/views/welcomepage/welcome-b.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/welcome-c",
                name: "welcomeC",
                component: () => import("@/views/welcomepage/welcome-c.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/trading-signals",
                name: "tradingSignals",
                component: () => import("@/views/trader/trading-signals.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
            {
                path: "/forex-trading",
                name: "forexTrading",
                component: () => import("@/views/trader/forex-trading.vue"),
                requiresAuth: false,
                beforeEnter: customCheckForPhoneCountryCase, 

            },
        ],
    },
];

const router = createRouter({
    base: process.env.NODE_ENV === "production" ? "/" : "/",
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) { 
        if(to.name != from.name){
            document.getElementById("app").scrollIntoView({ behavior: "smooth" });
        } else {
            return savedPosition
        }
    },
});
// const haveLiveAccount = () => {
//     try{
//         const store = myStore();
//         if(store.user.access_token && store.userTradingAccountsList.length){
//             let data = store.userTradingAccountsList.filter( i=> i.demo === false);
//             if(data.length){
//                 return true
//             }else{
//                 return false
//             }
//         }else{  
//             return false
//         }
//     }catch(e){
//         return false
//     }
// }

// Example function to check custom conditions before entering the parent route
function customCheckForPhoneCountryCase(to,from) {
    const store = myStore();
    const exception_pages= [
        'risk disclaimer',
        'declaration_of_consent',
        'conflict_of_intrest_policy',
        'privacy policy',
        'terms of service'
      ];
    let getZuluToken = localStorage.getItem("zulutoken") || ''
    if(store?.user?.access_token || getZuluToken?.length){
        if(store.customerDetail === null || Object.keys(store.customerDetail).length === 0 && exception_pages.includes(from.name)){
            store.callCustomerDetail();
        } else if(!exception_pages.includes(to.name) && ( !store.customerDetail || !Object.keys(store.customerDetail).length || !store.customerDetail.emailVerified || !store.customerDetail.phoneNumber.length || !store.customerDetail.countryIsoCodeA3.length)){
            store.callCustomerDetail();
        }
    }
}
// redirect to correct route for leader detail page
function redirectCorrectRoute(to, from, next) {
    const store = myStore();
    const routeExists = routes.some(route => route.path === to.path);
    const traderId = to.params.id;
    const isValid = /^\d+$/.test(traderId);
    const isTradeHistoryIndividual = /tradehistoryindividual\.aspx\?pid=(\d+)/i;
    const matchTradeHistoryIndividual = to.fullPath.match(isTradeHistoryIndividual);
    let match = to.fullPath.match(/trader\/(\d+)/);
    if (!isValid && match) {
        let targetURL = window.location.origin+'/'+match[0]+'/trading'
        targetURL = targetURL.replace(/\(\w+\)/g,'')
        window.top.location.href = targetURL
    } else if (matchTradeHistoryIndividual && matchTradeHistoryIndividual[1]) {
        let targetURL = window.location.origin+'/trader/'+matchTradeHistoryIndividual[1]+'/trading'
        targetURL = targetURL.replace(/\(\w+\)/g,'')
        window.top.location.href = targetURL
    } else if(routeExists){
        if(to.path == '/sign-up-investor' || to.path == '/sign-up-trader-program' || to.path == '/create-account' || to.path == '/signup-intermediate' || to.path == '/signup'){
            let path = `${static_vars.domainURL}register`;
            if(Object.keys(to.query).length){
                let qs = new URLSearchParams(to.query);
                path += `?${qs.toString()}`;
            }
            window.open(path,'_self')
        } else if(to.path == '/sign-up-affiliate-program'){
            window.open(`https://www.zulutrade.com/partners/`,'_self')
        } else if(to.path == '/forgot-password'){
            window.open(`${static_vars.domainURL}register/forgot-password`,'_self')
        } else if(to.path == '/update-password'){
            if(store.user.access_token){
                next({ path: '/dashboard/change-password' });
            }else{
                next({ path: '/' });
            }
        } else if(to.path == '/simulate'){
            next({path : '/simulation'});
        } else if (to.path == '/zulutrade-gateway'){
            next({ path: '/' });
        } else if (to.path == '/zulutrade-client' || to.path == '/charts') {
            next({ path: '/leaders' })
        } else if (to.path == '/traders/winning/30' || to.path == '/tradewall' || to.path == '/&quot' || to.path == '/index' || to.path == '/index.2001' || to.path == '/Index.asp' || to.path == '/Index.aspx' || to.path == '/index3') {
            next({ path: '/' })
        } else if (to.path == '/UserGuide.aspx'){
            next({ path: '/user-guide' })
        } else if (to.path == '/list/help-center-questions'){
            next({ path: '/help-center' })
        }
    } else {
        window.open(`${static_vars.domainURL}404.html`,'_self');
        return false
    }
}
router.beforeEach((to) => {
    if(to.path == '/'){
        window.location.href=`${static_vars.domainURL}`
    }
    const store = myStore();
    const badge = document.getElementsByClassName('grecaptcha-badge');
    if (badge.length) {
        badge[0].style.visibility = (to.path == '/login' || to.name == 'dashboardinfo') ? 'visible' : 'hidden';
    }
    if(to.name=='register' || to.name=='widgets' || to.name=='banner' || to.name == 'traderInfo'){
        document.querySelector('body').classList.add("hideChatPage");
         document.querySelector('body').classList.add("affiliate");
    } else {
        document.querySelector('body').classList.remove("hideChatPage");
         document.querySelector('body').classList.remove("affiliate");
    }

    //save token in User
    if (localStorage.getItem("zulutoken") !== null) {
        let json = {};
        json["access_token"] = localStorage.getItem("zulutoken");
        if (localStorage.getItem("zulurefreshToken") !== null) {
            json["refresh_token"] = localStorage.getItem("zulurefreshToken");
        }
        store.$patch({ user: json });
    } 

    //==============Redirection URl for 1.0 ===============================//
    if(to.fullPath == '/user#portfolio' || to.fullPath == '/user#portfolio/automator' || to.fullPath == '/user#pending-orders' || to.fullPath == '/user#trade-history/cancelled'){
        if(store.user.access_token){
            return { path: '/dashboard' }
        }else{
            return { path: '/' }
        }
    }else if(to.fullPath == '/user#feed'){
        return {path : '/social-feed'}
    }else if(to.path == '/notifications'){
        if(!store.user.access_token){
            return { path: '/' }
        }
    }else if(to.path == '/trade-details'){
        return { path: '/' }
    }else if(to.path == '/disclaimer' || to.path == '/mobile/risk-disclaimer'){
        return { path: '/risk-disclaimer' }
    }else if(to.path == '/privacy'){
        return { path: '/privacy-policy' }
    }else if(to.path == '/mobile/faq' || to.path == '/mobile/support' || to.path == '/avoid-hedging-guide' || to.path == '/capital-protection-info'){
        if(store.user.access_token){
            return { path: '/dashboard/need-help' }
        }else{
            return { path: '/' }
        }
    }else if(to.path == '/trading-guides' || to.path == '/eu-guide' || to.path == '/platforms' || to.path == '/combos' || to.path == '/combos-plus' || to.path == '/combos-plus' || to.path == '/cryptos' || to.path == '/zuluscript-guide'){//to.path == '/welcome-a' || to.path == '/welcome-b' || to.path == '/welcome-c' || 
        return { path: '/' }
    }else if((to.path == '/social-forex-trading') || (to.path == '/stock-trading') || (to.path == '/cryptocurrency-trading') || (to.path == '/commodities-trading') || (to.path == '/index-trading')){
        return {path : '/markets'}
    }else if(to.path == '/trader-guide'){
        return {path : '/leader-guide'}
    }else if(to.path == '/list'){
        return {path : '/watchlist'}
    } else if(to.path == '/settings'){
        return {path : '/dashboard/personal-info'}
    }else if(to.path == '/open-new-investror-account'){
        return {path : '/select-broker'}
    }
    else if(to.path == '/moble-faqs'){
        return {path : '/mobile-faqs'}
    }
    //====================END===============================================//        
    //when we hit path with token
    if(to.query.token){
        store.reset()
        if(store.user.access_token){
            let data = store.user
            data.access_token = to.query.token
            if(to.query.refresh_token){
                store.$patch({'user' : {'access_token' : to.query.token,'refresh_token' : to.query.refresh_token}})
            }else{
                store.$patch({'user' : {'access_token' : to.query.token}})
            }
        } else {
            if(to.query.refresh_token) {
                store.$patch({'user' : {'access_token' : to.query.token,'refresh_token' : to.query.refresh_token}})
            }else{
                store.$patch({'user' : {'access_token' : to.query.token}})
            }
        }
        if (localStorage.getItem("zulutoken") !== null) {
            localStorage.removeItem("zulutoken");
           let json = {};
            if (localStorage.getItem("zulurefreshToken") !== null) {
                localStorage.removeItem("zulurefreshToken");
                if(to.query.refresh_token){
                    json["refresh_token"] = to.query.refresh_token;
                }
            }
           json['access_token'] = to.query.token
            localStorage.setItem('zulutoken', to.query.token);
            if(to.query.refresh_token){
                localStorage.setItem('zulurefreshToken', to.query.refresh_token);
            }
            // store.$patch({'user' : json})
        }else{
          localStorage.setItem('zulutoken', to.query.token);
          if(to.query.refresh_token){
            localStorage.setItem('zulurefreshToken', to.query.refresh_token);
          }
        }
        if(to.query.redirect_to){
            if(to.query.target=='mobile'){
                router.push({name : to.query.redirect_to, query: {target:'mobile'}})
            } else {
                router.push({name : to.query.redirect_to})
            }
        } else {
            router.replace(to.path)
        }
    }
    // Theme(Dark || Light) mode in widget's
    if(to.query.theme){
        localStorage.setItem('theme',to.query.theme)
        document.documentElement.setAttribute('data-theme', to.query.theme)
    }
    // if (to.path == '/') return { name: 'index' }
    if (to.path == '/become-a-trader') return { path: '/become-a-leader' }
    if (to.path == '/support') return { path: '/help-center' }
    if (to.path == '/careers') return { path: '/about' }
    if (to.path == '/automated-trading') return { path: '/' }
    if (to.path == '/forex-tools') return { path: '/trading-tools' }
    if (to.path == '/forex-tools/pip-calculator') return { path: '/trading-tools/pip-calculator' }  
    if (to.path == '/forex-tools/profit-calculator') return { path: '/trading-tools/profit-calculator' }    
    if (to.path == '/traders') return { path: '/leaders' }
    if (to.path == '/rates') return { path: '/markets' }
    if (to.path == '/moble-faqs') return { path: '/mobile-faqs' }
    if (to.meta.requiresAuth && !store.user.access_token && (to.name == "dashboardinfo" || to.name == "tradingAccounts" || to.name == "feedBack")) return { path: "/" };
    if (to.meta.requiresAuth && !store.user.access_token && (to.name == "dashboard_portfolio")) return { path: "/" };
    if (to.meta.requiresAuth && store.user.access_token && !store.customerDetail.completed && (to.name == "tradingAccounts")) return { path: "/dashboard/personal-info" };
    // if (
    //     store.user.access_token &&
    //     (!store.customerDetail.completed || !haveLiveAccount() || store.customerDetail.requiredActions)&&
    //     to.name == "subscription"
    // )
    //     return { path: "/dashboard/personal-info" };
    // return true

    if (to.path == '/login' && store.user.access_token) return { path: '/dashboard' }
    if (to.path == '/dashboard/leader-widget'){
        if(store.user.access_token){
            if(!store.isLeaderList.length){
                return { path: '/' }
            }
        } else {
            return { path: '/' }
        }
    } 
    if (to.path == '/trading-tools/currency-calculator') return { path: '/trading-tools/currency-converter' }
    if (to.path == '/dashboard/subscription' || to.path == '/payment/redirect' || to.path == '/payment/in-process' || to.path == '/payment/success' || to.path == '/payment/failure') return { path: '/leaders' }
    if (to.path == '/widgets/menu') return { path: '/' }
});
router.afterEach((to) => {
    nextTick(() => {
        let seoJson = {}
        seoJson = seoData[0]
        let key = to.path.split('/');
        key = key.filter(i => i).join('/')
        let getSeoPath = seoJson[key]
        if(getSeoPath){
            document.title = getSeoPath.META_TITLE;
            document.querySelector('meta[name="description"]').setAttribute("content", getSeoPath.META_DESCRIPTION);
        } 
        // Canonical Tag
        const existingLink = document.querySelector('link[rel="canonical"]');
        if (existingLink) { existingLink.remove() }
        const fullUrl = document.location.href.split('#')[0];
        const currentUrl = fullUrl.split('?')[0];
        if (currentUrl) {
            const link = document.createElement('link');
            link.rel = 'canonical';
            link.href = currentUrl;
            document.head.appendChild(link);
        }
        // hreflang annotations for all language variations
        document.querySelectorAll('link[rel="alternate"]').forEach(link => link.remove());
        const lang = ['en','x-default']
        lang.map(k=>{
            const link = document.createElement('link');
            link.rel = 'alternate',
            link.hreflang = k,
            link.href = currentUrl
            document.head.appendChild(link);
        })
    });
});

export default router;
 