export default {
    imagesURL: 'https://www.zulutrade.com/',

    domainURL: `${process.env.VUE_APP_DOMAIN_URL}`,
    affilateURL: `${process.env.VUE_APP_AFFILIATE_URL}`,
    OldWebsiteURL : 'https://old.zulutrade.com/',
    CLIENT_ID: `${process.env.VUE_APP_CLIENT_ID}`,
    CLIENT_SECRET: `${process.env.VUE_APP_CLIENT_SECRET}`,
    // /* sandbox */ CHECKOUT_PUBLIC_API_KEY: 'pk_sbox_jwvr24ls3dgmosm5hf2kwgd6qam',
    /* production */ CHECKOUT_PUBLIC_API_KEY: 'pk_diflje6hb4a3y6mfdcxtsxmumqy',
    recaptchaKey : `${process.env.VUE_APP_RECAPTCHA_KEY}`,
    marketImageSURL : 'https://finvasiaexpos.s3.eu-west-2.amazonaws.com/tradingsymbol/',
    marketSocket: 'wss://www.zulutrade.com:443/api/ws/zulutradewsclient/websocket',
    riskTooltip: "Indicates how risky is the Leader's Strategy. Calculated using Sharpe Ratio formula, inverted and normalized on 1-5 scale (1 Low Risk & 5 High Risk)",
    limitPerPage: 10,
    leaderCantCopy: "You can't copy any leader",
    pipsValue : 50, // pips value
    defaultpipMultiplier: 100, // default PipMultiplier
    setCookie:(cname,cvalue,exdays)=>{
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires=" + ((cvalue)?d.toUTCString():new Date());
      document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";
    },
    getCookie:(cname)=>{
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    delete_cookie:(name) => {
      document.cookie = name +'=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    // getThemeMode:(mode)=>{
    //     mode = localStorage.getItem('theme')
    //     return mode
    // },
    tradingPerformaceFilterJson : {
        0 : 'Include Unrealized PnL',
        1 : 'Closed Trades Only',
        2 : 'Trade Volume',
        3 : 'By Pair',
        4 : 'Best-Worst',
        5 : 'Ranking',
    },
    tradingPairsFilterJson : {
        1: 'Pairs',
        2 : 'Trading Timezone'
    },
    roiTooltip: `<p class="f-12 mb-1">Return of investment (ROI) represents the efficiency of the strategy for the selected period.</p>
    <p class="f-12 mb-1">Cumulative ROI (shows if Trading < 52 Weeks) = PnL / Initial investment amount</p>
    <p class="f-12 mb-1">Annualized ROI (shows if Trading > 52 Weeks) = (Initial Capital + PnL / Initial Capital) ^ (365 / Tradeable Days) - 1</p>
    <p class="f-10 mb-0">* Any deposits or withdrawals during this period have been included in the calculation, adjusting the ROI using this formula.</p>
    <p class="f-10 mb-0">** Previous ROI calculation based on NME = 71.9%</p>`,

    economicCalendarCountries: {
        australia: [
            "Australia",
            "Fiji",
            "Kiribati",
            "New Caledonia",
            "New Zealand",
            "Papua New Guinea",
            "Samoa",
            "Solomon Islands",
            "Tonga",
            "Vanuatu",
        ],
        europe: [
            "Albania",
            "Andorra",
            "Austria",
            "Belarus",
            "Belgium",
            "Bosnia and Herzegovina",
            "Bulgaria",
            "Croatia",
            "Cyprus",
            "Czech Republic",
            "Denmark",
            "Estonia",
            "Euro area",
            "Faroe Islands",
            "Finland",
            "France",
            "Germany",
            "Greece",
            "Hungary",
            "Iceland",
            "Ireland",
            "Isle of Man",
            "Italy",
            "Kosovo",
            "Latvia",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Macedonia",
            "Malta",
            "Moldova",
            "Monaco",
            "Montenegro",
            "Netherlands",
            "Norway",
            "Poland",
            "Portugal",
            "Romania",
            "Russia",
            "Serbia",
            "Slovakia",
            "Slovenia",
            "Spain",
            "Sweden",
            "Switzerland",
            "Turkey",
            "Ukraine",
            "United Kingdom",
        ],
        america: [
            "Antigua and Barbuda",
            "Argentina",
            "Aruba",
            "Bahamas",
            "Barbados",
            "Belize",
            "Bermuda",
            "Bolivia",
            "Brazil",
            "Canada",
            "Cayman Islands",
            "Chile",
            "Colombia",
            "Costa Rica",
            "Cuba",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "El Salvador",
            "Grenada",
            "Guatemala",
            "Guyana",
            "Haiti",
            "Honduras",
            "Jamaica",
            "Mexico",
            "Nicaragua",
            "Panama",
            "Paraguay",
            "Peru",
            "Puerto Rico",
            "Suriname",
            "Trinidad and Tobago",
            "United States",
            "Uruguay",
            "Venezuela",
        ],
        africa: [
            "Algeria",
            "Angola",
            "Benin",
            "Botswana",
            "Burkina Faso",
            "Burundi",
            "Cameroon",
            "Cape Verde",
            "Central African Republic",
            "Chad",
            "Comoros",
            "Congo",
            "Djibouti",
            "Egypt",
            "Equatorial Guinea",
            "Eritrea",
            "Ethiopia",
            "Gabon",
            "Gambia",
            "Ghana",
            "Guinea",
            "Guinea Bissau",
            "Ivory Coast",
            "Kenya",
            "Lesotho",
            "Liberia",
            "Libya",
            "Madagascar",
            "Malawi",
            "Mali",
            "Mauritania",
            "Mauritius",
            "Morocco",
            "Mozambique",
            "Namibia",
            "Niger",
            "Nigeria",
            "Republic of the Congo",
            "Rwanda",
            "Sao Tome and Principe",
            "Senegal",
            "Seychelles",
            "Sierra Leone",
            "Somalia",
            "South Africa",
            "South Sudan",
            "Sudan",
            "Swaziland",
            "Tanzania",
            "Togo",
            "Tunisia",
            "Uganda",
            "Zambia",
            "Zimbabwe",
        ],
        asia: [
            "Afghanistan",
            "Armenia",
            "Azerbaijan",
            "Bahrain",
            "Bangladesh",
            "Bhutan",
            "Brunei",
            "Cambodia",
            "China",
            "East Timor",
            "Georgia",
            "Hong Kong",
            "India",
            "Indonesia",
            "Iran",
            "Iraq",
            "Israel",
            "Japan",
            "Jordan",
            "Kazakhstan",
            "Kuwait",
            "Kyrgyzstan",
            "Laos",
            "Lebanon",
            "Macao",
            "Malaysia",
            "Maldives",
            "Mongolia",
            "Myanmar",
            "Nepal",
            "North Korea",
            "Oman",
            "Palestine",
            "Pakistan",
            "Philippines",
            "Qatar",
            "Saudi Arabia",
            "Singapore",
            "South Korea",
            "Sri Lanka",
            "Syria",
            "Taiwan",
            "Tajikistan",
            "Thailand",
            "Turkmenistan",
            "United Arab Emirates",
            "Uzbekistan",
            "Vietnam",
            "Yemen",
        ],
        major: [
            "United States",
            "Euro Area",
            "China",
            "Japan",
            "Germany",
            "United Kingdom",
            "France",
            "India",
            "Italy",
            "Brazil",
            "Canada",
            "South Korea",
            "Russia",
            "Spain",
            "Australia",
            "Mexico",
            "Indonesia",
            "Turkey",
            "Netherlands",
            "Switzerland",
            "Saudi Arabia",
            "Argentina",
            "South Africa",
            "Singapore",
        ]
    },
    timezonesCalendar: [
        { label: "System Default", value: 0 },
        { label: "European Central Time(GMT +1)", value: 60 },
        { label: "Eastern European Time(GMT +2)", value: 120 },
        { label: "Eastern African Time(GMT +3)", value: 180 },
        { label: "Middle East Time(GMT +3:30)", value: 210 },
        { label: "Near East Time(GMT +4)", value: 240 },
        { label: "Pakistan Lahore Time(GMT +5)", value: 300 },
        { label: "India Standard Time(GMT +5:30)", value: 330 },
        { label: "Bangladesh Standard Time(GMT +6)", value: 360 },
        { label: "Vietnam Standard Time(GMT +7)", value: 420 },
        { label: "China Taiwan Time(GMT +8)", value: 480 },
        { label: "Japan Standard Time(GMT +9)", value: 540 },
        { label: "Australia Central Time(GMT +9:30)", value: 570 },
        { label: "Australia Eastern Time(GMT +10)", value: 600 },
        { label: "Solomon Standard Time(GMT +11)", value: 660 },
        { label: "New Zealand Standard Time(GMT +12)", value: 720 },
        { label: "Midway Islands Time(GMT -11)", value: -660 },
        { label: "Hawaii Standard Time(GMT -10)", value: -600 },
        { label: "Alaska Standard Time(GMT -9)", value: -540 },
        { label: "Pacific Standard Time(GMT -8)", value: -480 },
        { label: "Phoenix Standard Time(GMT -7)", value: -420 },
        { label: "Central Standard Time(GMT -6)", value: -360 },
        { label: "Eastern Standard Time(GMT -5)", value: -300 },
        { label: "Puerto Rico and US Virgin Islands Time(GMT -4)", value: -240 },
        { label: "Canada Newfoundland Time(GMT -3:30)", value: -210 },
        { label: "Brazil Eastern Time(GMT -3)", value: -180 },
        { label: "Central African Time(GMT -1)", value: -60 }
    ],
    BlogsArray : [
        {
            "id": 3,
            "count": 87,
            "description": "",
            "link": "https://blog.zulutrade.com/category/knowledge-crunch/",
            "name": "Knowledge Crunch",
            "slug": "knowledge-crunch",
            "taxonomy": "category",
            "parent": 0,
            "meta": []
        },
        {
            "id": 2,
            "count": 2273,
            "description": "",
            "link": "https://blog.zulutrade.com/category/market-view/",
            "name": "Market View",
            "slug": "market-view",
            "taxonomy": "category",
            "parent": 0,
            "meta": []
        },
        {
            "id": 4,
            "count": 27,
            "description": "",
            "link": "https://blog.zulutrade.com/category/new-features/",
            "name": "New Features",
            "slug": "new-features",
            "taxonomy": "category",
            "parent": 0,
            "meta": []
        },

        {
            "id": 5,
            "count": 41,
            "description": "",
            "link": "https://blog.zulutrade.com/category/trading-floor/",
            "name": "Trading Floor",
            "slug": "trading-floor",
            "taxonomy": "category",
            "parent": 0,
            "meta": []
        }
    ],
    coBrands: [
        {
            "id": 2,
            "brokerName": "FXView Global",
            "brokerurl": "/assets/images/clientLogo/fxview-small.svg",
            "platForm": "MT4, MT5, ACT",
            "trackingURL": "https://global.fxview.com/register?refLink=MzI5&refRm=NDkw",
            "EUtrackingURL": "https://fxview.com/?refLink=MzI5&refRm=NDkw",
            "regulated": "South Africa by FSCA",
            "EUregulated": "Cyprus by CySec",
            "deposit": "$100",
            "currency": "USD,EUR,GBP,AUD",
            "type": "global"
        },
        {
            "id": 3,
            "brokerName": "FX Centrum Global",
            "brokerurl": "/assets/images/clientLogo/fxc-logo-2.svg",
            "platForm": "XOH",
            "trackingURL": "https://clientzone.fxcentrum.com/auth/create-account/?src=130922",
            "EUtrackingURL": "https://clientzone.fxcentrum.com/auth/create-account/?src=130922",
            "regulated": "Seychelles by FSA",
            "deposit": "$50",
            "currency": "USD,EUR,ZAR,NGN,THB,INR,VND,ZMK,BRL,IDR,crypto and many more",
            "type": "global"
        },
        {
            "id": 5,
            "brokerName": "ActivTrades Global",
            "brokerurl": "/assets/images/clientLogo/activTrade_logo.svg",
            "platForm": "MT4, MT5",
            "trackingURL": "https://afftracking.activtrades.com/visit/?bta=35286&nci=5827",
            "EUtrackingURL": "https://afftracking.activtrades.com/visit/?bta=35286&nci=5827",
            "regulated": "United Kingdom by FCA",
            "deposit": "$500",
            "currency": "EUR,USD,CHF",
            "type": "global"
        },
        {
            "id": 6,
            "brokerName": "Blackbull Markets Global",
            "brokerurl": "/assets/images/clientLogo/blackBull_logo.svg",
            "platForm": "MT4",
            "trackingURL": "https://blackbullmarkets.com/en/?cxd=39889_424128",
            "EUtrackingURL": "https://blackbullmarkets.com/en/?cxd=39889_424128",
            "regulated": "New Zealand by FMA",
            "deposit": "$200",
            "currency": "USD,EUR,NZD,AUD",
            "type": "global"
        },
        {
            "id": 7,
            "brokerName": "Finveo Global",
            "brokerurl": "/assets/images/clientLogo/finveo_logo.png",
            "platForm": "MT4",
            "trackingURL": "https://www.finveo.mn/en/open-an-account?utm_source=zulutrade&utm_campaign=affiliate&utm_medium=socialtrading",
            "EUtrackingURL": "https://www.finveo.mn/en/open-an-account?utm_source=zulutrade&utm_campaign=affiliate&utm_medium=socialtrading",
            "regulated": "Montenegro by CMA",
            "deposit": "$100",
            "currency": "EUR, USD",
            "type": "global"
        },
        {
            "id": 8,
            "brokerName": "ITCS Capital Global",
            "brokerurl": "/assets/images/clientLogo/itcs_logo.png",
            "platForm": "XOH ",
            "trackingURL": "https://live.itcscapital.com/signup/ZuluTrade/K77FnRHk",
            "EUtrackingURL": "https://live.itcscapital.com/signup/ZuluTrade/K77FnRHk",
            "regulated": "Kazakhstan by ARDFM RK",
            "deposit": "$200",
            "currency": "EUR,USD",
            "type": "global"
        },
        {
            "id": 9,
            "brokerName": "Libertex Global",
            "brokerurl": "/assets/images/clientLogo/libertex_logo.svg",
            "platForm": "MT4",
            "trackingURL": "https://go.libertex.com/visit/?bta=49034&nci=7622",
            "EUtrackingURL": "https://go.libertex.com/visit/?bta=49034&nci=7622",
            "regulated": "Saint Vincent by SVGFSA",
            "deposit": "$50",
            "currency": "USD, MXN",
            "type": "global"
        },
        {
            "id": 12,
            "brokerName": "Moneta Global",
            "brokerurl": "/assets/images/clientLogo/MONT-small.svg",
            "platForm": "MT4",
            "trackingURL": "https://go.monetamarkets.com/visit/?bta=36979&brand=monetamarkets",
            "EUtrackingURL": "https://go.monetamarkets.com/visit/?bta=36979&brand=monetamarkets",
            "regulated": "Saint Vincent by SVGFSA",
            "deposit": "$200",
            "currency": "EUR,USD",
            "type": "global"
        },
        /*{
            "id": 15,
            "brokerName": "UEXO Global",
            "brokerurl": "/assets/images/clientLogo/uexoLogo.svg",
            "platForm": "MT5",
            "trackingURL": "https://gotomynewacount.com/click.php?project_id=f7b3286315&affiliate_id=29f5c73f66",
            "EUtrackingURL": "https://gotomynewacount.com/click.php?project_id=f7b3286315&affiliate_id=29f5c73f66",
            "regulated": "Seychells / South Africa",
            "deposit": "$50",
            "currency": "EUR,USD,GBP",
            "type": "global"
        },*/
        {
            "id": 13,
            "brokerName": "Vantage Global",
            "brokerurl": "/assets/images/clientLogo/vantage_logo.svg",
            "platForm": "MT4",
            "trackingURL": "https://go.vantagemarkets.com/visit/?bta=48273&brand=vantagefx",
            "EUtrackingURL": "https://go.vantagemarkets.com/visit/?bta=48273&brand=vantagefx",
            "regulated": "Cayman Islands by CIMA",
            "deposit": "$100",
            "currency": "USD,EUR,GBP,AUD,CAD",
            "type": "global"
        },
        {
            "id": 14,
            "brokerName": "Tickmill Global",
            "brokerurl": "/assets/images/clientLogo/tickmill_logo.svg",
            "platForm": "MT4",
            "trackingURL": "",
            "EUtrackingURL": "",
            "regulated": "Seychelles by FSA",
            "deposit": "100 USD",
            "currency": "EUR,GBP,USD",
            "type": "global"
        },
        {
            "id": 16,
            "brokerName": "Deltastock EU",
            "brokerurl": "/assets/images/clientLogo/deltaStock.svg",
            "platForm": "MT5",
            "trackingURL": "https://www.deltastock.com/english/platforms/MT5-zulu.asp?mktg=zulu&acs=М47747",
            "EUtrackingURL": "https://www.deltastock.com/english/platforms/MT5-zulu.asp?mktg=zulu&acs=М47747",
            "regulated": "Bulgaria by FSC",
            "deposit": "100 USD, 100 EUR or 200 BGN.",
            "currency": "EUR. USD, BGN",
            "type": "eu"
        },
        {
            "id": 17,
            "brokerName": "Hextra Prime",
            "brokerurl": "/assets/images/clientLogo/hextra-prime.png",
            "platForm": "-",
            "trackingURL": "",
            "EUtrackingURL": "",
            "regulated": "-",
            "deposit": "-",
            "currency": "-",
            "type": "-"
        },
        {
            "id": 11,
            "brokerName": "AAAFx International",
            "brokerurl": "/assets/images/clientLogo/AAAFx_logo.svg",
            "platForm": "MT4",
            "trackingURL": "https://my.aaafx.com/register",
            "EUtrackingURL": "https://v1.aaafx.com/sign-up",
            "regulated": "Saint Vincent by SVGFSA",
            "deposit": "$300",
            "currency": "EUR,USD,GBP,JPY,AUD,CHF,CAD",
            "type": "global"
        },
       
        // {
        //     "id": 15,
        //     "brokerName": "Tickmill EU",
        //     "brokerurl": "/assets/images/tickmill_1.png",
        //     "platForm": "MT4",
        //     "trackingURL": "",
        //     "regulated": "",
        //     "deposit": "",
        //     "currency": "",
        //     "type": "eu"
        // },
        // {
        //     "id": 10,
        //     "brokerName": "AAAFx EU",
        //     "brokerurl": "/assets/images/clientLogo/AAAFx_logo.png",
        //     "platForm": "MT4, MT5",
        //     "trackingURL": "https://v1.aaafx.com/sign-up",
        //     "regulated": "Saint Vincent by SVGFSA",
        //     "deposit": "$300",
        //     "currency": "EUR,USD,GBP,JPY,AUD,CHF,CAD",
        //     "type": "eu"
        // },
        // {
        //     "id": 4,
        //     "brokerName": "Deltastock EU",
        //     "brokerurl": "/assets/images/clientLogo/deltaStock.png",
        //     "platForm": "MT5",
        //     "trackingURL": "https://www.deltastock.com/english/platforms/MT5-zulu.asp?mktg=zulu&acs=М47747",
        //     "regulated": "",
        //     "deposit": "",
        //     "currency": "EU",
        //     "type": "eu"
        // },
        // {
        //     "id": 1,
        //     "brokerName": "FXview EU",
        //     "brokerurl": "/assets/images/fxview-small.png",
        //     "platForm": "MT4, MT5, ACT",
        //     "trackingURL": "https://fxview.com/?refLink=MzI5&refRm=NDkw",
        //     "regulated": "Saint Vincent by SVGFSA",
        //     "deposit": "$100",
        //     "currency": "USD,EUR,GBP,AUD",
        //     "type": "eu"
        // },
    ],
    cretioCountryJson: {
        'AE': '104592',
        'AR': '104593',
        'AT': '104594',
        'AU': '103297',
        'BR': '104595',
        'CA': '104596',
        'CH': '104597',
        'CZ': '104598',
        'DE': '103300',
        'ES': '103296',
        'GR': '103294',
        'ID': '104599',
        'IT': '103060',
        'MY': '104600',
        'NL': '103308',
        'PH': '104601',
        'PL': '103295',
        'QA': '104602',
        'SA': '104603',
        'SG': '104604',
        'TH': '104605',
        'ZA': '104606',
    },
    SubscriptionMonthJson : {
        '1' : '1 Month',
        '3' : '3 Months',
        '6' : '6 Months',
        '12': '12 Months'
    },
    SubscriptionDiscountJson : {
        '1' : '0%',
        '3' : '5%',
        '6' : '10%',
        '12': '17%'
    },
    freeSubscriptionID : [289,305,36,197,41,158,318],
    socialTrade : ['socialTrading','traders','rates','pricingPage','simulation','downloadApp'],
    propTrade : ['propTrading','accountAnalyzer','challengesOverview','rulesAndDefinitions','helpAndSupport','downloadPlatform','Compare','withdrawPage'],

}