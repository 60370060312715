import { __assign, __spreadArrays } from "tslib";
import { defineStore, getActivePinia } from "pinia";
import { useToast } from "vue-toastification";
var toast = useToast();
import * as API from "@/api/rx-factory";
import * as ENDPOINTS from "@/api/endpoints";
import moment from "moment";
var qs = require("qs");
import router from "@/router";
import static_vars from '@/staticVars';
import PriceFeed from "@/store/stomp";
import LoginFeed from '@/store/login-stomp';
var tostTypeError = { type: "error" };
var tostTypeSuccess = { type: "success" };
var tostTypeInfo = { type: "info" };
export var myStore = defineStore({
    id: "userSettingStore",
    persist: {
        key: "userSetting",
        storage: window.localStorage,
        paths: [
            "user",
            "topTrdaersList",
            "newsfeedList",
            //"traderDetail",
            "currencyList",
            //"tradesHistory",
            "currencyPairs",
            "socialList",
            //"socialTypeList",
            //"allTradersList",
            "countryList",
            //"customViewList",
            "countriesList",
            "userDetail",
            "watchList",
            // "subscriptionSelected",
            "assetTradesList",
            "assetListWithID",
            "customerDetail",
            // "rewardsDetails",
            // "leaderCopyInfo",
            "userTradingAccountsList",
            "userSelectedAccount",
            "userDashboardDetail",
            "dashboardTradesTab",
            "copyTraders",
            "allCurrencySymbols",
            "allTypeAccount",
            "userChartWithLeader",
            "userNotificationList",
            "usertopNotifications",
            "flavordata",
            "leaderWatchList",
            //"calulatorBaseCurrencies",
            "userProfileImage",
            "isLeaderList",
            "forexListRates",
            "brokerAccountSettings",
            "brokerTicketsList"
        ],
    },
    state: function () { return ({
        loading: false,
        singleLoading: false,
        tradesLoading: false,
        user: {},
        isLogin: false,
        topTrdaersList: {},
        marketSearch: {},
        newsfeedList: {},
        traderDetail: {},
        currencyList: {},
        combosDataList: [],
        topFiveFollowers: [],
        tradesHistory: {},
        openPosition: {},
        currencyPairs: [],
        tradingProfitChart: {},
        socialList: {},
        tradingPerformanceChart: {},
        socialTypeList: {},
        socialTypeCommentList: {},
        socialHastagRightList: [],
        socialHastagList: {},
        socialLiveFollowersList: {},
        socialTopCommentPublicName: [],
        socialTopCommentHastagName: [],
        socialFeedSearchHastagName: [],
        tradingPairsChart: {},
        tradingDrawDownChart: {},
        tradingSlippageChart: {},
        LiveFollowers: {},
        followersFollowingList: {},
        followersHasFollowedList: {},
        followersChartData: {},
        searchTradersList: [],
        compareTraderPerformance: {},
        allTradersList: {},
        countryList: [],
        TradercurrencyList: [],
        customViewList: [],
        socialCommentList: {},
        socialFeedPageMyHastags: [],
        countriesList: {},
        userDetail: {},
        watchList: [],
        leaderWatchList: [],
        marketWatchList: [],
        allPrices: {},
        lastSocketUpdate: null,
        forexList: {},
        commoditiesList: {},
        cryptoList: {},
        indicesList: {},
        stocksList: {},
        forexListRates: {},
        commoditiesListRates: {},
        cryptoListRates: {},
        indicesListRates: {},
        stocksListRates: {},
        // subscriptionPlans: [],
        // subscriptionCards: [],
        // subscriptionSelected: {},
        transactionsList: [],
        topWinnersListRates: {},
        topLoosersListRates: {},
        mostPolularCurrencyListRates: {},
        topWinnersList: {},
        topLoosersList: {},
        mostPolularCurrencyList: {},
        assetDetail: {},
        assetTradesList: {},
        assetNewsList: {},
        marketNews: {},
        assetPendindTradesList: {},
        assetTradesHistoryList: {},
        assetListWithID: {},
        customerDetail: {},
        assetTradingConvesrionData: {},
        rewardsDetails: {},
        withdrawalHistory: {},
        rewardsHistory: {},
        withdrawalfunds: {},
        existingPayment: {},
        leaderCopyInfo: {},
        userTradingAccountsList: [],
        userSelectedAccount: {},
        marketClosedDays: {},
        copyTraders: {},
        providerOpenTrades: [],
        providerPendingTrades: [],
        providerHistoryTrades: [],
        assetSearchList: {},
        userDashboardDetail: {},
        openTradeFullDetail: {},
        dashboardTradesTab: 'open',
        allCurrencySymbols: {},
        tradingAccountTitleDesc: {},
        brokerAccountDetail: {},
        allTypeAccount: null,
        userChartWithLeader: {},
        userNotificationList: {},
        usertopNotifications: {},
        flavordata: {},
        calendarData: [],
        calendarTickerData: {},
        calulatorBaseCurrencies: [],
        userProfileImage: '',
        // subscriptionSelectPlanUpdated: [],
        getConnectedAccounts: [],
        isGiftPopup: false,
        isLeaderList: [],
        widgetChartData: [],
        allBrokersList: [],
        questionariesStatus: {},
        sutabilityQuestionsList: {},
        brokerAccountSettings: {},
        dashboardTradesHistory: {},
        categoryWiseMarket: {},
        priorityPayment: '',
        // unlimitSavedCards: [],
        refreshLoader: false,
        allCountryList: [],
        brokerTicketsList: [],
    }); },
    getters: {},
    actions: {
        liveAccounts: function () {
            if (this.userTradingAccountsList.length) {
                var data = this.userTradingAccountsList.filter(function (i) { return !i.demo; });
                if (data.length) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },
        login: function (params, loader, token, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                var data = qs.stringify(params);
                API.post(ENDPOINTS.EP_USER_LOGIN, data, token)
                    .then(function (response) {
                    _this.loading = false;
                    if (response.access_token) {
                        if (_that.$route.name == 'register' || _that.$route.name == 'welcomeA') {
                            if (params['googleToken']) {
                                _that.SetToken(response);
                                _this.user = response;
                                _that.step = 3;
                            }
                            else {
                                _that.SetToken(response);
                                _this.user = response;
                                _that.callCustomerDetail();
                            }
                        }
                        else {
                            _this.loading = true;
                            _that.SetToken(response);
                            _this.user = response;
                            _that.callSocket();
                            _this.callCustomerDetail().then(function (res) {
                                if (res) {
                                    _this.loading = false;
                                    router.replace("/dashboard");
                                }
                            });
                            // router.go(-1)
                            // static_vars.setCookie('zulutoken',response.access_token,'100d')
                            // static_vars.setCookie('zulurefreshToken',response.refresh_token,'100d')
                            //_that.UserDetail();
                            // _that.allCategoryPrices();
                            //_that.getWatchList();
                            //_that.getCustomerDetail();
                            //_that.getCopyLeaders();
                            //_that.getDashboardData();
                        }
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        register: function (params, loader, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                API.post(ENDPOINTS.EP_REGISTRATION, params).then(function (response) {
                    _this.loading = true;
                    if (params['tokenType'] != 'GOOGLE') {
                        _that.CretioFunc();
                        _that.loginMannual();
                    }
                    else {
                        _that.CretioGoogleFunc();
                        _that.loginWithGoogle(params['token']);
                    }
                    resolve(response);
                }).catch(function (error) {
                    if (error.response.status && error.response.status == 404) {
                        _that.loginWithGoogle(params['token']);
                        _this.loading = true;
                        reject(error);
                    }
                    else {
                        _this.loading = false;
                        reject(error);
                    }
                });
            });
        },
        refreshToken: function (params, loader, token, _that, from) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = false;
                }
                _this.refreshLoader = true;
                var inputjson = JSON.parse(JSON.stringify(params));
                if (params['tokenType']) {
                    delete params['tokenType'];
                }
                var data = qs.stringify(params);
                API.post(ENDPOINTS.EP_USER_LOGIN, data, token).then(function (response) {
                    _this.loading = false;
                    _this.refreshLoader = false;
                    if (response.access_token) {
                        localStorage.setItem('zulutoken', response.access_token);
                        localStorage.setItem('zulurefreshToken', response.refresh_token);
                        _this.user = response;
                        //_that.getFlavorID()
                        if (from) {
                            _that.callUserDetail();
                        }
                    }
                    resolve(response);
                }).catch(function (error) {
                    _this.loading = false;
                    _this.refreshLoader = false;
                    reject(error);
                });
            });
        },
        //create Demo Account
        createDemoAccount: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = true; }
            var _that = this;
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                var ep = ENDPOINTS.EP_DEMO_ACCOUNT;
                ep = ep.replace("{{customerId}}", _that.customerDetail.id);
                API.post(ep, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.callCustomerDetail({}, false);
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        //Get Currency Rates
        currencyrateslist: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_GET_CALCULATE_RATES)
                    .then(function (response) {
                    _this.loading = false;
                    _this.currencyList = response;
                    // resolve(response)
                })
                    .catch(function (error) {
                    _this.loading = false;
                    // reject(error);
                });
            });
        },
        // Calculator
        pipcalculate: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.loadingCalculator = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CALCULATE_PIP;
                ep = ep.replace("{{currency}}", params['currencyId']);
                ep = ep.replace("{{lot}}", params['lots']);
                ep = ep.replace("{{lotSize}}", params['stdLots']);
                ep = ep.replace("{{pips}}", params['pips']);
                ep = ep.replace("{{baseCurrency}}", params['baseCurrency']);
                API.get(ep)
                    .then(function (response) {
                    _that.loadingCalculator = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.loadingCalculator = false;
                    reject(error);
                });
            });
        },
        margincalculate: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.loadingCalculator = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CALCULATE_MARGIN;
                ep = ep.replace("{{currency}}", params['currencyId']);
                ep = ep.replace("{{lotSize}}", params['stdLots']);
                ep = ep.replace("{{leverage}}", params['leverage']);
                ep = ep.replace("{{baseCurrency}}", params['baseCurrency']);
                if (params['amount']) {
                    ep = ep.concat("&amount=" + params['amount']);
                }
                else {
                    ep = ep.concat("&lot=" + params['lots']);
                }
                API.get(ep)
                    .then(function (response) {
                    _that.loadingCalculator = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.loadingCalculator = false;
                    reject(error);
                });
            });
        },
        getLeverageList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_LEVERAGE_LIST)
                    .then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getConverterCurrencies: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_CONVERTER_CURRENCY)
                    .then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getPipsCurrencies: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_PIPS_CURRENCY)
                    .then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getBaseCurrencies: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_BASECURRENCY)
                    .then(function (response) {
                    _this.loading = false;
                    _this.calulatorBaseCurrencies = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        profitcalculate: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.loadingCalculator = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CALCULATE_PROFIT;
                ep = ep.replace("{{currency}}", params['currencyId']);
                ep = ep.replace("{{lot}}", params['lots']);
                ep = ep.replace("{{lotSize}}", params['stdLots']);
                ep = ep.replace("{{openPrice}}", params['openPrice']);
                ep = ep.replace("{{closePrice}}", params['closePrice']);
                ep = ep.replace("{{baseCurrency}}", params['baseCurrency']);
                API.get(ep)
                    .then(function (response) {
                    _that.loadingCalculator = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.loadingCalculator = false;
                    reject(error);
                });
            });
        },
        currencycalculate: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.loadingCalculator = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CONVERT_CURRENCY;
                ep = ep.replace("{{amount}}", params['amount']);
                ep = ep.replace("{{currencyFrom}}", params['currencyFrom']);
                ep = ep.replace("{{currencyTo}}", params['currencyTo']);
                API.get(ep)
                    .then(function (response) {
                    _that.loadingCalculator = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.loadingCalculator = false;
                    reject(error);
                });
            });
        },
        callTopTradersList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_TOP_TRADER_LIST;
                if (Object.keys(_this.flavordata).length && _this.flavordata.flavorId) {
                    ep = ep.replace('{{flavorId}}', _this.flavordata.flavorId);
                    ep = ep.replace('{{accessingFlavorId}}', _this.flavordata.flavorId);
                }
                else {
                    ep = ep.replace('{{flavorId}}', '1');
                    ep = ep.replace('{{accessingFlavorId}}', '1');
                }
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    // console.log('responseresponse',response[0].result)
                    _this.topTrdaersList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        calltraderDetail: function (params, loader, id, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                if (_that.$route.name == 'dashboard_portfolio') {
                    this.singleLoading = true;
                }
                else {
                    this.loading = true;
                }
            }
            return new Promise(function (resolve, reject) {
                var ep = (!_this.user.access_token) ? ENDPOINTS.EP_GET_TRADER_DETAIL : ENDPOINTS.EP_GET_TRADER_DETAIL_SECURED;
                ep = ep.replace("{{TradeID}}", id);
                if (Object.keys(_this.flavordata).length && _this.flavordata.flavorId) {
                    ep = ep.replace('{{flavorId}}', _this.flavordata.flavorId);
                    if (_this.flavordata.flavorId) {
                        var region = (!_this.flavordata.isEu) ? 'global' : 'eu';
                        ep = ep.replace('{{region}}', region);
                    }
                }
                else {
                    ep = ep.replace('{{flavorId}}', '1');
                    ep = ep.replace('{{region}}', 'global');
                }
                API.get(ep)
                    .then(function (response) {
                    if (_that.$route.name == 'dashboard_portfolio') {
                        _this.singleLoading = false;
                    }
                    else {
                        _this.loading = false;
                    }
                    _this.traderDetail = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    if (_that.$route.name == 'dashboard_portfolio') {
                        _this.singleLoading = false;
                    }
                    else {
                        _this.loading = false;
                    }
                    reject(error);
                });
            });
        },
        callCustomerDetail: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CUSTOMER_DETAIL;
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.customerDetail = response;
                    var except_paths = [
                        'risk disclaimer',
                        'declaration_of_consent',
                        'conflict_of_intrest_policy',
                        'privacy policy',
                        'terms of service'
                    ];
                    var isPathInExceptPaths = except_paths.includes(router.currentRoute.value.name.toString());
                    //check email verification and personal details, if not redirect to otp/ personal details page
                    // if (!this.customerDetail.emailVerified ) 
                    if (isPathInExceptPaths == false && !_this.customerDetail.emailVerified) {
                        window.open(static_vars.domainURL + "register/get-otp", '_self');
                    }
                    else if (isPathInExceptPaths == false && (_this.customerDetail.phoneNumber == '' || _this.customerDetail.countryIsoCodeA3 == '' || !_this.customerDetail.phoneNumber || !_this.customerDetail.countryIsoCodeA3)) {
                        window.open(static_vars.domainURL + "register/personal-details", '_self');
                    }
                    // else if(isPathInExceptPaths == false && (!this.customerDetail.accounts || !Object.keys(this.customerDetail.accounts).length)){
                    //   window.open(`${static_vars.domainURL}register/demo-acc`,'_self');
                    // }
                    if (response.accounts && Object.keys(response.accounts).length) {
                        var accData_1 = [];
                        Object.values(response.accounts).map(function (i) {
                            if (i['zuluAccountType'] && i['zuluAccountType'] != 'AFFILIATE') {
                                accData_1.push(i);
                            }
                        });
                        if (accData_1.length) {
                            _this.userTradingAccountsList = accData_1;
                            _this.isLeaderList = _this.userTradingAccountsList.filter(function (i) { return (i.demo === false) && (i.isLeader) && (i.providerStatus == 'APPROVED'); });
                            if (_this.allTypeAccount) {
                                var account = _this.allTypeAccount;
                                _this.allTypeAccount = account;
                                _this.userSelectedAccount = {};
                            }
                            else {
                                if (Object.keys(_this.userSelectedAccount).length) {
                                    var data_1 = _this.userSelectedAccount;
                                    // this.userSelectedAccount = data
                                    if (_this.userTradingAccountsList.length) { //to overiride old cache
                                        var accounts = _this.userTradingAccountsList.map(function (i) { return i.brokerAccountId; });
                                        if (accounts.length && accounts.includes(data_1.brokerAccountId) && (Object.entries(data_1).toString() === Object.entries(_this.userSelectedAccount).toString())) {
                                            var matchAcc = _this.userTradingAccountsList.find(function (acc) { return acc.zuluAccountId == data_1.zuluAccountId; });
                                            var allLiveAccount = _this.userTradingAccountsList.filter(function (i) { return i.demo === false; });
                                            var everyDisconnected = allLiveAccount.every(function (item) { return item.connectivityStatus !== 'Connected'; });
                                            if (matchAcc.connectivityStatus !== 'Connected') {
                                                if (everyDisconnected) {
                                                    _this.userSelectedAccount = _this.userTradingAccountsList.find(function (i) { return i.demo === true; });
                                                }
                                                else {
                                                    _this.userSelectedAccount = allLiveAccount.find(function (account) { return account.connectivityStatus === 'Connected'; });
                                                }
                                            }
                                            else {
                                                _this.userSelectedAccount = data_1;
                                            }
                                        }
                                        else {
                                            _this.userSelectedAccount = _this.userTradingAccountsList.find(function (account) { return account.connectivityStatus === 'Connected'; });
                                        }
                                    }
                                }
                                else {
                                    _this.userSelectedAccount = _this.userTradingAccountsList.find(function (account) { return account.connectivityStatus === 'Connected'; });
                                }
                            }
                        }
                        else {
                            _this.userTradingAccountsList = [];
                            _this.userSelectedAccount = {};
                        }
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        /*** Social Feed -- start ***/
        callSocialList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = "";
                // if(this.socialList && this.socialList.content && this.socialList.content.length && this.socialList.includesLast==false && EventID){
                //   console.log('3')
                //   ep = ENDPOINTS.EP_GET_SOCIAL_AUTOLOAD_LIST
                //   ep = ep.replace("{{TradeID}}",id)
                //   ep = ep.replace("{{EventID}}",EventID)
                // } else {
                ep = ENDPOINTS.EP_GET_SOCIAL_LIST;
                ep = ep.replace("{{TradeID}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.socialList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        callSocialLoadMoreList: function (params, loader, id, EventID) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_LOADMORE_LIST;
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{EventID}}", EventID);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.socialList.content = _this.socialList.content.concat(response.content);
                    _this.socialList.includesLast = response.includesLast;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callSocialTypeList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_TYPE_LIST;
                ep = ep.replace("{{TradeID}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.socialTypeList = response;
                    if (_this.socialTypeList.content && _this.socialTypeList.content[0] && !_this.socialTypeList.content[0].zulutradeEventId) {
                        _this.socialTypeCommentList = {};
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        callSocialTypeCommentList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_TYPE_COMMENT_LIST;
                ep = ep.replace("{{ZuluEventUD}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    response.comments = response.comments.reverse();
                    _this.socialTypeCommentList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callSocialCommentList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_COMMENT_LIST;
                ep = ep.replace("{{ZuluEventUD}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    response.comments = response.comments.reverse();
                    if (_this.socialList && _this.socialList.content && _this.socialList.content.length) {
                        _this.socialList.content.forEach(function (val) {
                            if (val.eventId == id) {
                                val.comments = response.comments.reverse();
                            }
                        });
                    }
                    _this.socialCommentList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callSocialHastagRightList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_HASTAG_RIGHT_LIST;
                ep = ep.replace("{{ZuluID}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.socialHastagRightList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callSocialHastagList: function (params, loader, id, noAuth) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_HASTAG_LIST;
                ep = ep.replace("{{Hastag}}", id);
                API.get(ep, noAuth)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.socialList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        callSocialHastagLoadMoreList: function (params, loader, id, EventID, Hastag) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_HASTAG_LOADMORE_LIST;
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{EventID}}", EventID);
                ep = ep.replace("{{Hastag}}", Hastag);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.socialList.content = _this.socialList.content.concat(response.content);
                    _this.socialList.includesLast = response.includesLast;
                    _this.socialList.hashtagId = response.hashtagId;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callSocialLiveFollowersList: function (params, loader, id, page) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_LIVE_FOLLOWERS_LIST;
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{page}}", page);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.socialLiveFollowersList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callSocialTopCommentPublicName: function (params, loader, name) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_TOP_COMMENT_PUBLIC_NAME;
                ep = ep.replace("{{publicName}}", name);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.socialTopCommentHastagName = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callSocialTopCommentHastagName: function (params, loader, name) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_TOP_COMMENT_HASHTAG;
                ep = ep.replace("{{hastagName}}", name);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.socialTopCommentHastagName = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callSocialFeedSearchHastagName: function (params, loader, name) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_TOP_COMMENT_HASHTAG;
                ep = ep.replace("{{hastagName}}", name);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.socialFeedSearchHastagName = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callSocialFeedPageList: function (params, loader, noAuth) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_SOCIAL_FEED_PAGE_LIST, noAuth)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.socialList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        callSocialFeedPageLoadMoreList: function (params, loader, EventID, noAuth) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_FEED_PAGE_LOADMORE_LIST;
                ep = ep.replace("{{EventID}}", EventID);
                API.get(ep, noAuth)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.socialList.content = _this.socialList.content.concat(response.content);
                    _this.socialList.includesLast = response.includesLast;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callSocialFeedPageHastagLoadMoreList: function (params, loader, EventID, Hastag, noAuth) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_FEED_PAGE_HASTAG_LOADMORE_LIST;
                ep = ep.replace("{{EventID}}", EventID);
                ep = ep.replace("{{Hastag}}", Hastag);
                API.get(ep, noAuth)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.socialList.content = _this.socialList.content.concat(response.content);
                    _this.socialList.includesLast = response.includesLast;
                    _this.socialList.hashtagId = response.hashtagId;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callSocialFeedPageHastagRightList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_SOCIAL_FEED_PAGE_HASTAG_RIGHT_LIST)
                    .then(function (response) {
                    _this.loading = false;
                    _this.socialHastagRightList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callSocialLikeUnlike: function (params, loader, id, status, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_LIKE;
                ep = ep.replace("{{SocialEventID}}", id);
                ep = ep.replace("{{status}}", status);
                API[status == "like" ? "post" : "put"](ep, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    if (type == "social") {
                        if (_this.socialList && _this.socialList.content && _this.socialList.content.length) {
                            _this.socialList.content.forEach(function (val) {
                                if (val.socialEventId == id) {
                                    if (val.liked) {
                                        val.liked = false;
                                        val.likes -= 1;
                                    }
                                    else {
                                        val.liked = true;
                                        val.likes += 1;
                                    }
                                }
                            });
                        }
                    }
                    else if (type == "socialType") {
                        if (_this.socialTypeCommentList) {
                            if (_this.socialTypeCommentList.liked) {
                                _this.socialTypeCommentList.liked = false;
                                _this.socialTypeCommentList.likes -= 1;
                            }
                            else {
                                _this.socialTypeCommentList.liked = true;
                                _this.socialTypeCommentList.likes += 1;
                            }
                        }
                    }
                    else if (type == "socialChild") {
                        if (_this.socialList && _this.socialList.content && _this.socialList.content.length) {
                            _this.socialList.content.forEach(function (val) {
                                if (val.comments && val.comments.length) {
                                    val.comments.forEach(function (itm) {
                                        if (itm.socialEventId == id) {
                                            if (itm.liked) {
                                                itm.liked = false;
                                                itm.likes -= 1;
                                            }
                                            else {
                                                itm.liked = true;
                                                itm.likes += 1;
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }
                    else if (type == "socialTypeChild") {
                        if (_this.socialTypeCommentList && _this.socialTypeCommentList.comments && _this.socialTypeCommentList.comments.length) {
                            _this.socialTypeCommentList.comments.forEach(function (val) {
                                if (val.socialEventId == id) {
                                    if (val.liked) {
                                        val.liked = false;
                                        val.likes -= 1;
                                    }
                                    else {
                                        val.liked = true;
                                        val.likes += 1;
                                    }
                                }
                            });
                        }
                    }
                    else if (type == "socialDetail") {
                        if (Object.keys(_this.socialCommentList).length) {
                            if (_this.socialCommentList.liked) {
                                _this.socialCommentList.liked = false;
                                _this.socialCommentList.likes -= 1;
                            }
                            else {
                                _this.socialCommentList.liked = true;
                                _this.socialCommentList.likes += 1;
                            }
                        }
                    }
                    else if (type == "socialChildDetail") {
                        if (Object.keys(_this.socialCommentList).length && _this.socialCommentList.comments && _this.socialCommentList.comments.length) {
                            _this.socialCommentList.comments.forEach(function (val) {
                                if (val.socialEventId == id) {
                                    if (val.liked) {
                                        val.liked = false;
                                        val.likes -= 1;
                                    }
                                    else {
                                        val.liked = true;
                                        val.likes += 1;
                                    }
                                }
                            });
                        }
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        deleteSocialComment: function (params, loader, id, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_DELETE;
                ep = ep.replace("{{SocialEventID}}", id);
                API.DELETE(ep)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    if (type == "social") {
                        if (_this.socialList && _this.socialList.content && _this.socialList.content.length) {
                            _this.socialList.content = _this.socialList.content.filter(function (val) { return val.socialEventId != id; });
                        }
                    }
                    else if (type == "socialType") {
                        if (_this.socialTypeList && _this.socialTypeList.content && _this.socialTypeList.content.length) {
                            _this.socialTypeList.content = _this.socialTypeList.content.filter(function (val) { return val.id != id; });
                        }
                    }
                    else if (type == "socialChild") {
                        if (_this.socialList && _this.socialList.content && _this.socialList.content.length) {
                            _this.socialList.content.forEach(function (val) {
                                if (val.comments && val.comments.length) {
                                    var decreaseComment = val.comments.find(function (val) { return val.socialEventId == id; });
                                    if (decreaseComment) {
                                        val.totalComments -= 1;
                                    }
                                    val.comments = val.comments.filter(function (val) { return val.socialEventId != id; });
                                }
                            });
                        }
                    }
                    else if (type == "socialTypeChild") {
                        if (_this.socialTypeCommentList && _this.socialTypeCommentList.comments && _this.socialTypeCommentList.comments.length) {
                            _this.socialTypeCommentList.comments = _this.socialTypeCommentList.comments.filter(function (val) { return val.socialEventId != id; });
                        }
                    }
                    // if(response){
                    //   toast("Successfully Deleted", tostTypeSuccess);
                    // }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        reportComment: function (params, loader, id, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_SOCIAL_FEED_REPORT, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    if (type == "social") {
                        if (_this.socialList && _this.socialList.content && _this.socialList.content.length) {
                            _this.socialList.content = _this.socialList.content.filter(function (val) { return val.socialEventId != id; });
                        }
                    }
                    else if (type == "socialType") {
                        if (_this.socialTypeList && _this.socialTypeList.content && _this.socialTypeList.content.length) {
                            _this.socialTypeList.content = _this.socialTypeList.content.filter(function (val) { return val.id != id; });
                        }
                    }
                    else if (type == "socialChild") {
                        if (_this.socialList && _this.socialList.content && _this.socialList.content.length) {
                            _this.socialList.content.forEach(function (val) {
                                if (val.comments && val.comments.length) {
                                    val.comments = val.comments.filter(function (val) { return val.socialEventId != id; });
                                    val.totalComments -= 1;
                                }
                            });
                        }
                    }
                    else if (type == "socialTypeChild") {
                        if (_this.socialTypeCommentList && _this.socialTypeCommentList.comments && _this.socialTypeCommentList.comments.length) {
                            _this.socialTypeCommentList.comments = _this.socialTypeCommentList.comments.filter(function (val) { return val.socialEventId != id; });
                            _this.socialTypeCommentList.totalComments -= 1;
                        }
                    }
                    if (response) {
                        toast("You have reported this message. Our Moderators will review the issue you are reporting and we contact you in case we need additional information.", tostTypeSuccess);
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        postSocialTopComment: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_POST_SOCIAL_TOP_COMMENT, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    // if(response){
                    //   toast("Your post has beed listed successfully", tostTypeSuccess);
                    // }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        postSocialComment: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_POST_SOCIAL_COMMENT;
                ep = ep.replace("{{SocialEventID}}", id);
                API.post(ep, params)
                    .then(function (response) {
                    _this.loading = false;
                    if (_this.socialList && _this.socialList.content && _this.socialList.content.length) {
                        _this.socialList.content.forEach(function (val) {
                            if (val.socialEventId == id) {
                                val.totalComments += 1;
                            }
                        });
                    }
                    // toast('Successfully Posted', tostTypeSuccess)
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        postSocialCommentEdit: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_POST_SOCIAL_COMMENT_EDIT;
                ep = ep.replace("{{SocialEventID}}", id);
                API.put(ep, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    // if(response){
                    //   toast("Successfully Updated", tostTypeSuccess);
                    // }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        socialTranslate: function (params, loader, id, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            var checkKeys = {
                social: "socialList",
                socialType: "socialTypeList",
                socialChild: "socialCommentList",
                socialTypeChild: "socialTypeCommentList",
            };
            var checkKeys2 = {
                social: "content",
                socialType: "content",
                socialChild: "comments",
                socialTypeChild: "comments",
            };
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.SOCIAL_REPORT, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    if (type == 'socialDetail') {
                        if (Object.keys(_this.socialCommentList).length && _this.socialCommentList.content) {
                            _this.socialCommentList["translated"] = response.result;
                        }
                    }
                    else if (type == 'socialChildDetail') {
                        if (Object.keys(_this.socialCommentList).length && _this.socialCommentList.comments && _this.socialCommentList.comments.length) {
                            _this.socialCommentList.comments.forEach(function (val, i) {
                                if (val.socialEventId == id) {
                                    _this.socialCommentList.comments[i]["translated"] = response.result;
                                }
                            });
                        }
                    }
                    else if (type == 'leaderProfile') {
                        if (_this.traderDetail.trader.stats.profile && _this.traderDetail.trader.stats.profile.strategyDescApproved && _this.traderDetail.trader.stats.profile.strategyDesc) {
                            _this.traderDetail.trader.stats.profile['translated'] = response.result;
                        }
                    }
                    else {
                        if (_this[checkKeys[type]] && _this[checkKeys[type]][checkKeys2[type]] && _this[checkKeys[type]][checkKeys2[type]].length) {
                            _this[checkKeys[type]][checkKeys2[type]].forEach(function (val, i) {
                                if (val.socialEventId == id) {
                                    _this[checkKeys[type]][checkKeys2[type]][i]["translated"] = response.result;
                                }
                            });
                        }
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getSocialFeedMyHastags: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_SOCIAL_FEED_MY_HASTAGS)
                    .then(function (response) {
                    _this.loading = false;
                    _this.socialFeedPageMyHastags = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        postSocialFeedFollow: function (params, loader, id, status) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_FEED_HASTAG_FOLLOW;
                ep = ep.replace("{{hastagID}}", id);
                API[status == "followed" ? "DELETE" : "post"](ep, params)
                    .then(function (response) {
                    if (_this.socialHastagRightList && _this.socialHastagRightList.length) {
                        _this.socialHastagRightList.forEach(function (val) {
                            if (val.id == id) {
                                if (val.isFollowed) {
                                    val.isFollowed = false;
                                }
                                else {
                                    val.isFollowed = true;
                                }
                            }
                        });
                    }
                    if (_this.socialFeedSearchHastagName && _this.socialFeedSearchHastagName.length) {
                        _this.socialFeedSearchHastagName.forEach(function (val) {
                            if (val.id == id) {
                                if (val.isFollowed) {
                                    val.isFollowed = false;
                                }
                                else {
                                    val.isFollowed = true;
                                }
                            }
                        });
                    }
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callmyFeedPageList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_MY_FEED_PAGE_LIST)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.socialList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        callMyFeedPageLoadMoreList: function (params, loader, EventID) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_MY_FEED_PAGE_LOADMORE_LIST;
                ep = ep.replace("{{EventID}}", EventID);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.socialList.content = _this.socialList.content.concat(response.content);
                    _this.socialList.includesLast = response.includesLast;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callMyFeedPageHastagLoadMoreList: function (params, loader, EventID, Hastag) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_MY_PAGE_HASTAG_LOADMORE_LIST;
                ep = ep.replace("{{EventID}}", EventID);
                ep = ep.replace("{{Hastag}}", Hastag);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.socialList.content = _this.socialList.content.concat(response.content);
                    _this.socialList.includesLast = response.includesLast;
                    _this.socialList.hashtagId = response.hashtagId;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callSocialHastagMyFeedList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SOCIAL_HASTAG_MY_FEED_LIST;
                ep = ep.replace("{{Hastag}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.socialList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        /*** Social Feed -- end **/
        marketSearch: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_GET_MARKETS_SEARCH, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.marketSearch = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        newsfeedList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_GET_NEWSFEED_LIST, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.newsfeedList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        combosList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_COMBOS_LIST)
                    .then(function (response) {
                    _this.loading = false;
                    _this.combosDataList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getTopFiveFollowersList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_TOP_FIVE_FOLLOWERS_LIST;
                ep = ep.replace("{{TradeID}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.topFiveFollowers = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getLiveFollowersList: function (params, loader, id, page) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_FOLLOWERS_LIST;
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{page}}", page);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.LiveFollowers = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callTradingHistory: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = "";
                if (params["id"]) {
                    ep = ENDPOINTS.EP_GET_TRADE_HISTORY_CURRENCY_LIST;
                }
                else {
                    ep = ENDPOINTS.EP_GET_TRADE_HISTORY_LIST;
                }
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{timeframe}}", params["timeframe"]);
                ep = ep.replace("{{page}}", params["page"]);
                ep = ep.replace("{{size}}", params["size"]);
                ep = ep.replace("{{sort}}", params["sort"]);
                if (params["id"]) {
                    ep = ep.replace("{{id}}", params["id"]);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.tradesHistory = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callOpenPosition: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_OPEN_POSITION_LIST;
                ep = ep.replace("{{TradeID}}", id);
                if (params["cId"]) {
                    ep = ep = ep.concat("&cid=" + params["cId"]);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.openPosition = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callCurrencyPairsList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CURRENCY_PAIRS_LIST;
                ep = ep.replace("{{feedGroupId}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.currencyPairs = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        allChartData: function (query, loader, id) {
            var _this = this;
            if (query === void 0) { query = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CHART_DATA;
                ep = ep.replace("{{TradeID}}", id);
                var queries = [];
                for (var key in query) {
                    if (query[key]) {
                        queries.push(key + "=" + query[key]);
                    }
                }
                ep += queries.join('&') || '';
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.widgetChartData = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callTradingProfitChartData: function (params, loader, id, timeframe, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = "";
                if (params["cid"] && params["cid"].length) {
                    ep = ENDPOINTS.EP_GET_TRADING_PROFIT_CHART_DATA_WITH_CURRENCY;
                    ep = ep.replace("{{cid}}", params["cid"]);
                }
                else if (params["cid"] && params["openTrades"] && params["openTrades"] == 1) {
                    ep = ENDPOINTS.EP_GET_TRADING_PROFIT_CHART_DATA;
                }
                else {
                    ep = ENDPOINTS.EP_GET_TRADING_PROFIT_CHART_DATA;
                }
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{timeframe}}", timeframe);
                if (type == _this.traderDetail.trader.stats.profile.baseCurrencyName) {
                    if (params["openTrades"] == 1) {
                        ep = ep.replace("{{type}}", "TOTAL_PROFIT_MAX_OPEN_TRADES_MONEY");
                    }
                    else {
                        ep = ep.replace("{{type}}", "TOTAL_PROFIT_MONEY");
                    }
                }
                else if (type == "Pips") {
                    if (params["openTrades"] == 1) {
                        ep = ep.replace("{{type}}", "TOTAL_PROFIT_MAX_OPEN_TRADES");
                    }
                    else {
                        ep = ep.replace("{{type}}", "TOTAL_PROFIT");
                    }
                }
                else if (type == "ROI") {
                    ep = ep.replace("{{type}}", "TOTAL_PROFIT_ROI");
                }
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.tradingProfitChart = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callTradingPerformanceChartData: function (params, loader, id, timeframe, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = "";
                if (type == "RANKING") {
                    ep = ENDPOINTS.EP_GET_TRADING_PERFORMANCE_RANKING_CHART_DATA;
                }
                else {
                    ep = ENDPOINTS.EP_GET_TRADING_PERFORMANCE_CHART_DATA;
                    ep = ep.replace("{{type}}", type);
                }
                ep = ep.replace("{{TradeID}}", id);
                if (type == "BEST_TRADE,WORST_TRADE") {
                    ep = ep.replace("{{timespan}}", "DAILY");
                }
                else if (type == "CURRENCY_CLOSED_PROFIT_MONEY" || type == "CURRENCY_CLOSED_PROFIT") {
                    ep = ep.replace("{{timespan}}", "STATS");
                }
                else {
                    ep = ep.replace("{{timespan}}", "MONTHLY");
                }
                ep = ep.replace("{{timeframe}}", timeframe);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.tradingPerformanceChart = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callTradingPairsChartData: function (params, loader, id, timeframe, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_TRADING_CHART_DATA;
                ep = ep.replace("{{type}}", type);
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{timespan}}", "STATS");
                ep = ep.replace("{{timeframe}}", timeframe);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.tradingPairsChart = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callTrendingDrawDownChartData: function (params, loader, id, timeframe, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_TRADING_DRAWDOWN_CHART_DATA;
                ep = ep.replace("{{type}}", type);
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{timespan}}", "DAILY");
                ep = ep.replace("{{timeframe}}", timeframe);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    if (Object.keys(response).length && response.series && response.series.length) {
                        response.series.forEach(function (val) {
                            if (val.type == "DRAWDOWN_DAILY" || val.type == "DRAWDOWN_DAILY_MONEY") {
                                val.data.forEach(function (item) {
                                    item.y = Math.abs(item.y);
                                });
                            }
                        });
                        _this.tradingDrawDownChart = response;
                    }
                    else {
                        _this.tradingDrawDownChart = response;
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        getTrendingSlippageChartData: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_TRADING_SLIPPAGE_CHART_DATA;
                ep = ep.replace("{{TradeID}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.tradingSlippageChart = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        getFollowersFollowingList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_FOLLWERS_FOLLOWING_LIST;
                ep = ep.replace("{{TradeID}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.followersFollowingList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getFollowersHasFollowedList: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_FOLLWERS_HAS_FOLLOWED_LIST;
                ep = ep.replace("{{TradeID}}", id);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.followersHasFollowedList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callFollowersChartData: function (params, loader, id, brokerId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_FOLLWERS_CHART_DATA;
                ep = ep.replace("{{TradeID}}", id);
                ep = ep.replace("{{BrokerId}}", brokerId);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    if (Object.keys(response).length) {
                        var data = [];
                        if (response.followerChart && response.followerChart.series && response.followerChart.series.length && response.followerChart.series[0].data && response.followerChart.series[0].data.length) {
                            var json = { 'data': response.followerChart.series[0].data };
                            data.push(json);
                        }
                        if (response.traderChart && response.traderChart.series && response.traderChart.series.length && response.traderChart.series[0].data && response.traderChart.series[0].data.length) {
                            var json1 = { 'data': response.traderChart.series[0].data };
                            data.push(json1);
                        }
                        _this.followersChartData = data;
                    }
                    else {
                        _this.followersChartData = [];
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callFollowersSearchList: function (params, loader, search) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_SEARCH_TRADER_LIST;
                ep = ep.replace("{{name}}", search);
                if (Object.keys(_this.flavordata).length && _this.flavordata.flavorId) {
                    ep = ep.replace('{{accessingFlavorId}}', _this.flavordata.flavorId);
                    if (_this.flavordata.flavorId) {
                        var region = (!_this.flavordata.isEu) ? 'global' : 'eu';
                        ep = ep.replace('{{flavor}}', region);
                    }
                }
                else {
                    ep = ep.replace('{{accessingFlavorId}}', '1');
                    ep = ep.replace('{{flavor}}', 'global');
                }
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.searchTradersList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        compareTraderPerformanceData: function (params, loader, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_GET_TRADER_COMPARE_PERMORMANCE_DATA, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.compareTraderPerformance = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        callFollowersCompareList: function (params, loader, page, search, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_FOLLOWERS_COMPARE_LIST;
                if (!type) {
                    ep += '?accessingFlavorId={{accessingFlavorId}}&limit=5&page={{page}}&name={{name}}&sortBy={{sortBy}}&sortAsc=true&flavor={{flavor}}';
                    ep = ep.replace("{{page}}", page);
                    if (search) {
                        ep = ep.replace("{{sortBy}}", "providerName");
                    }
                    else {
                        ep = ep.replace("{{sortBy}}", "zuluRank");
                    }
                }
                else {
                    ep += '?accessingFlavorId={{accessingFlavorId}}&name={{name}}&flavor={{flavor}}';
                }
                ep = ep.replace("{{name}}", search);
                if (Object.keys(_this.flavordata).length && _this.flavordata.flavorId) {
                    ep = ep.replace('{{accessingFlavorId}}', _this.flavordata.flavorId);
                    if (_this.flavordata.flavorId) {
                        var region = (!_this.flavordata.isEu) ? 'global' : 'eu';
                        ep = ep.replace('{{flavor}}', region);
                    }
                }
                else {
                    ep = ep.replace('{{accessingFlavorId}}', '1');
                    ep = ep.replace('{{flavor}}', 'global');
                }
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.searchTradersList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callAllTradersList: function (params, loader, id, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.loadingAllLeader = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ALL_TRADERS_LIST;
                if (_that.page == 0 && !_that.filterPop && !_that.isCustomView) {
                    ep = ep.concat("?viewType=all&defaultViewId={{ID}}");
                    ep = ep.replace("{{ID}}", id);
                }
                API.post(ep, params)
                    .then(function (response) {
                    _that.loadingAllLeader = false;
                    if (_that.page >= 1) {
                        if (Object.keys(_this.allTradersList).length && _this.allTradersList.result && _this.allTradersList.result.length) {
                            _this.allTradersList.result = _this.allTradersList.result.concat(response.result);
                        }
                    }
                    else {
                        _this.allTradersList = response;
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.loadingAllLeader = false;
                    reject(error);
                });
            });
        },
        callfilteredTradersList: function (params, loader, id, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_FILTERED_ALL_TRADER_LIST;
                ep = ep.replace("{{ID}}", id);
                if (Object.keys(_this.flavordata).length && _this.flavordata.flavorId) {
                    ep = ep.replace('{{accessingFlavorId}}', _this.flavordata.flavorId);
                    if (_this.flavordata.flavorId) {
                        ep = ep.replace('{{flavorId}}', _this.flavordata.flavorId);
                    }
                }
                else {
                    ep = ep.replace('{{accessingFlavorId}}', '1');
                    ep = ep.replace('{{flavorId}}', '1');
                }
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    if (_that.page >= 1) {
                        if (Object.keys(_this.allTradersList).length && _this.allTradersList.result && _this.allTradersList.result.length) {
                            _this.allTradersList.result = _this.allTradersList.result.concat(response.result);
                        }
                    }
                    else {
                        _this.allTradersList = response;
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getTradersFlavorWise: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ALL_TRADERS_LIST;
                // if(params['base']){
                //   ep = ep.replace('www',params['base'])
                // }
                API.post(ep, params)
                    .then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callCountryList: function (params, loader, id, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_COUNTRIES_LIST;
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.countryList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        //Active or non active // for use in personal details phone number
        callAllCountryList: function (params, loader, id, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ALL_COUNTRY_LIST;
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.allCountryList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callCurrencyList: function (params, loader, id, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CURRENCIES_LIST;
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.TradercurrencyList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callAddtraderCustomView: function (params, loader, _that, editId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_ADD_TRADER_CUSTOM_VIEW;
                if (editId && editId != 'undefined') {
                    ep = ep.concat("/" + editId);
                }
                API[editId && editId != 'undefined' ? 'put' : 'post'](ep, params)
                    .then(function (response) {
                    _this.loading = false;
                    if (response === true) {
                        _this.customViewList.push(response);
                        _that.getAllTradersList(response);
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callCustomViewList: function (params, loader, type, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CUSTOM_VIEW_LIST;
                var method = "get";
                if (type == "list") {
                    method = "get";
                    ep = ep.concat("?type=3");
                }
                else if (type == "delete" && id) {
                    method = "DELETE";
                    ep = ep.concat("/" + parseInt(id));
                }
                API[method](ep)
                    .then(function (response) {
                    _this.loading = false;
                    if (type == "list") {
                        _this.customViewList = response.sort(function (a, b) { return b.lastUpdated - a.lastUpdated; });
                    }
                    else if (type == "delete") {
                        _this.customViewList = _this.customViewList.filter(function (val) { return parseInt(val.id) != parseInt(id); });
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getCountryList: function (params, loader, id, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_COUNTRY_LIST;
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.countriesList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getUserDetail: function (params, loader, _that, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = false;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_USER_DETAIL;
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.userDetail = response;
                    if (type && type == "Phone") {
                        _that.SendSMS();
                    }
                    if (type && type == 'Email') {
                        _that.getEmailCode();
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        userNamePUT: function (params, loader, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                API.put(ENDPOINTS.EP_PUT_USER_NAME, params)
                    .then(function (response) {
                    _this.loading = false;
                    _that.refreshToken();
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        verifyOTP: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.loader = true;
                }
                API.put(ENDPOINTS.EP_VERIFY_OTP, params).then(function (response) {
                    _that.loader = false;
                    if (response === true) {
                        _that.CretioFunc();
                        _that.showEmailCode = false;
                        _that.form.emailcode = '';
                        _that.successStep = 2;
                    }
                    resolve(response);
                }).catch(function (error) {
                    _that.loader = false;
                    reject(error);
                });
            });
        },
        resendOTP: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.loader = true;
                }
                API.post(ENDPOINTS.EP_RESEND_OTP, params).then(function (response) {
                    _that.loader = false;
                    resolve(response);
                }).catch(function (error) {
                    _that.loader = false;
                    reject(error);
                });
            });
        },
        callUpdateProfileInfo: function (params, loader, _that, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.singleLoading = true;
                }
                API.put(ENDPOINTS.EP_GET_USER_DETAIL, params)
                    .then(function (response) {
                    if (!_that.form.file.name) {
                        _this.singleLoading = false;
                        if (_that.$route.name == 'welcomeA') {
                            _that.getEmailCode();
                        }
                        else {
                            _this.userDetail = response;
                            if (type) {
                                if (type == 'Email') {
                                    _that.getEmailCode();
                                }
                                else {
                                    _that.SendSMS();
                                }
                            }
                            //  _that.refreshToken();
                        }
                        //_that.callUserDetail();
                    }
                    else {
                        _this.singleLoading = true;
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        uploadUserImage: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.singleLoading = true;
                }
                API.post(ENDPOINTS.EP_UPLOAD_USER_IMAGE, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.userProfileImage = new Date().getTime();
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        sendOtpMobile: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.phoneloader = true;
                }
                API.post(ENDPOINTS.EP_SEND_SMS_MOBILE, params)
                    .then(function (response) {
                    _that.phoneloader = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.phoneloader = false;
                    reject(error);
                });
            });
        },
        verifyMobileOTP: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.phoneloader = true;
                }
                API.put(ENDPOINTS.EP_VERIFY_MOBILE_OTP, params)
                    .then(function (response) {
                    _that.phoneloader = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.phoneloader = false;
                    reject(error);
                });
            });
        },
        getWatchList: function (params, loader, timeframe) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                var ep = ENDPOINTS.EP_GET_WATCHLIST;
                if (timeframe) {
                    ep = ep.replace("{{timeframe}}", timeframe);
                    if (parseInt(timeframe) == 1) {
                        ep = ep.concat('&type=MARKET');
                    }
                    else {
                        ep = ep.concat('&type=LEADER');
                    }
                }
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    if (parseInt(timeframe) == 1) {
                        _this.watchList = response;
                    }
                    else if (parseInt(timeframe) == 180) {
                        _this.leaderWatchList = response;
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        addToWatchList: function (params, loader, _that, type, watchType) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                var api = "post";
                if (type == "ADD") {
                    api = "post";
                }
                else if (type == "DELETE") {
                    api = "DELETE";
                }
                API[api](ENDPOINTS.EP_ADD_TO_WATCHLIST, params)
                    .then(function (response) {
                    _this.loading = false;
                    if (watchType) {
                        _that.callWatchList(watchType);
                    }
                    else {
                        _that.callWatchList();
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getRatePageCategories: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                var inputjson = JSON.parse(JSON.stringify(params));
                if (loader) {
                    _this.loading = true;
                }
                var ep = ENDPOINTS.EP_ASSET_SEARCH_LIST;
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("?targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (_this.user.access_token && Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("?targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                else {
                    ep = ENDPOINTS.EP_ASSET_SEARCH_LIST;
                }
                API.post(ep, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.singleLoading = false;
                    if (inputjson["categories"]) {
                        if (parseInt(inputjson["categories"]) == 1) {
                            _this.forexListRates = response;
                        }
                        if (parseInt(inputjson["categories"]) == 3) {
                            _this.commoditiesListRates = response;
                        }
                        if (parseInt(inputjson["categories"]) == 4) {
                            _this.cryptoListRates = response;
                        }
                        if (parseInt(inputjson["categories"]) == 7) {
                            _this.indicesListRates = response;
                        }
                        if (parseInt(inputjson["categories"]) == 8) {
                            _this.stocksListRates = response;
                        }
                    }
                    if (inputjson["sortingStrategy"]) {
                        if (inputjson["sortingStrategy"] == "TOP_WINNERS") {
                            if (Object.keys(response).length) {
                                Object.values(response).forEach(function (i) {
                                    if (i["hourlyChart"] && i["hourlyChart"].length) {
                                        i["hourlyChart"].forEach(function (val) {
                                            if (val.datetime) {
                                                val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                            }
                                        });
                                    }
                                });
                                _this.topWinnersListRates = response;
                            }
                        }
                        if (inputjson["sortingStrategy"] == "TOP_LOSERS") {
                            if (Object.keys(response).length) {
                                Object.values(response).forEach(function (i) {
                                    if (i["hourlyChart"] && i["hourlyChart"].length) {
                                        i["hourlyChart"].forEach(function (val) {
                                            if (val.datetime) {
                                                val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                            }
                                        });
                                    }
                                });
                                _this.topLoosersListRates = response;
                            }
                        }
                        if (inputjson["sortingStrategy"] == "MOST_POPULAR") {
                            if (Object.keys(response).length) {
                                Object.values(response).forEach(function (i) {
                                    if (i["hourlyChart"] && i["hourlyChart"].length) {
                                        i["hourlyChart"].forEach(function (val) {
                                            if (val.datetime) {
                                                val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                            }
                                        });
                                    }
                                });
                                _this.mostPolularCurrencyListRates = response;
                            }
                        }
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getAllCurrencyCategories: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                var inputjson = JSON.parse(JSON.stringify(params));
                if (loader) {
                    if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                        _this.singleLoading = true;
                    }
                    else {
                        _this.loading = true;
                    }
                }
                var ep = ENDPOINTS.EP_GET_ALL_CURRENCY_CATEGORIES;
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("?targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (_this.user.access_token && Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("?targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                else {
                    ep = ENDPOINTS.EP_GET_ALL_CURRENCY_CATEGORIES;
                }
                API.post(ep, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.singleLoading = false;
                    if (inputjson["categories"]) {
                        if (parseInt(inputjson["categories"]) == 1) {
                            if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                                if (Object.keys(response).length) {
                                    _this.forexList.elements = __assign(__assign({}, _this.forexList.elements), response.elements);
                                    _this.forexList.lastIncluded = response.lastIncluded;
                                }
                                else {
                                    _this.forexList = response;
                                }
                            }
                            else {
                                _this.forexList = response;
                            }
                        }
                        if (parseInt(inputjson["categories"]) == 3) {
                            if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                                if (Object.keys(response).length) {
                                    _this.commoditiesList.elements = __assign(__assign({}, _this.commoditiesList.elements), response.elements);
                                    _this.commoditiesList.lastIncluded = response.lastIncluded;
                                }
                                else {
                                    _this.commoditiesList = response;
                                }
                            }
                            else {
                                _this.commoditiesList = response;
                            }
                        }
                        if (parseInt(inputjson["categories"]) == 4) {
                            if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                                if (Object.keys(response).length) {
                                    _this.cryptoList.elements = __assign(__assign({}, _this.cryptoList.elements), response.elements);
                                    _this.cryptoList.lastIncluded = response.lastIncluded;
                                }
                                else {
                                    _this.cryptoList = response;
                                }
                            }
                            else {
                                _this.cryptoList = response;
                            }
                        }
                        if (parseInt(inputjson["categories"]) == 7) {
                            if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                                if (Object.keys(response).length) {
                                    _this.indicesList.elements = __assign(__assign({}, _this.indicesList.elements), response.elements);
                                    _this.indicesList.lastIncluded = response.lastIncluded;
                                }
                                else {
                                    _this.indicesList = response;
                                }
                            }
                            else {
                                _this.indicesList = response;
                            }
                        }
                        if (parseInt(inputjson["categories"]) == 8) {
                            if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                                if (Object.keys(response).length) {
                                    _this.stocksList.elements = __assign(__assign({}, _this.stocksList.elements), response.elements);
                                    _this.stocksList.lastIncluded = response.lastIncluded;
                                }
                                else {
                                    _this.stocksList = response;
                                }
                            }
                            else {
                                _this.stocksList = response;
                            }
                        }
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getMarketCaterogyWise: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var inputjson = JSON.parse(JSON.stringify(params));
                if (loader) {
                    _this.loading = true;
                }
                var ep = ENDPOINTS.EP_GET_MARKET_CATEROGY_WISE;
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("?targetBrokerAccountId=" + _this.getBrokerAccountID() + "&includeTopMarkets=false&includeTopFlops=false");
                    }
                }
                else if (_this.user.access_token && Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("?targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId + "&includeTopMarkets=false&includeTopFlops=false");
                }
                else {
                    ep = ENDPOINTS.EP_GET_MARKET_CATEROGY_WISE;
                }
                API.post(ep, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.categoryWiseMarket = response;
                    // console.log('==>',this.categoryWiseMarket)
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        /***  Subscription - Start ***/
        // subscriptionPlansList(params = {}, loader = false) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.get(ENDPOINTS.EP_GET_SUBSCRIPTION_PLANS)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         this.subscriptionPlans = response;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // subscriptionSelectPlan(params = {}, loader = false) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.post(ENDPOINTS.EP_GET_SELECT_PLAN, params)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         this.subscriptionSelected = response;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // subscriptionSelectPlanUpdate(params = {}, loader = false) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.get(ENDPOINTS.EP_GET_SELECT_PLAN, params)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         this.subscriptionSelectPlanUpdated = response;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        getConnectedAccountsList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_ACCOUNTS_CONNECTED, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.getConnectedAccounts = response === null || response === void 0 ? void 0 : response.brokers;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        getAllBrokersList: function (params, loader, isFlavor) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ALL_BROKERS_LIST;
                if (Object.keys(_this.flavordata).length) {
                    var region = (!_this.flavordata.isEu) ? 'global' : 'eu';
                    ep = ep.concat(isFlavor == 'withFlavor' ? "?flavor=" + region : '');
                }
                API.get(ep, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.allBrokersList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        // subscriptionCardList(params = {}, loader = false) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.get(ENDPOINTS.EP_GET_SAVED_CARDS)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         this.subscriptionCards = response;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // subscriptionPayment(params = {}, loader = false) {
        //   if (loader) {
        //     this.loading = true;
        //   }
        //   return new Promise((resolve, reject) => {
        //     API.post(ENDPOINTS.EP_INITIATE_PAYMENT, params)
        //       .then((response) => {
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.loading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // subscriptionCancelPlan(params = {}, loader = false) {
        //   if (loader) { this.loading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.DELETE(ENDPOINTS.EP_GET_CANCEL_PLAN)
        //       .then((response) => {
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.loading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // subscriptionSelectedPlan(params = {}, loader = false) {
        //   if (loader) {
        //     this.loading = true;
        //   }
        //   return new Promise((resolve, reject) => {
        //     API.get(ENDPOINTS.EP_GET_SELECTED_PLAN)
        //       .then((response) => {
        //         this.loading = false;
        //         this.subscriptionSelected = response;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.loading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // subscriptionTransactionList(params = {}, loader = false) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.get(ENDPOINTS.EP_GET_TRANSACTION_DETAILS)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         this.transactionsList = response;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // subscriptionSavedCheckout(params = {}, loader = false) {
        //   if (loader) { this.loading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.post(ENDPOINTS.EP_GET_SAVED_CUST_CHECKOUT, params)
        //       .then((response) => {
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.loading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // getUserDeatilFromCheckout(params = {}, loader = false) {
        //   if (loader) { this.loading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.get(ENDPOINTS.EP_GET_SAVED_CUST_CHECKOUT)
        //       .then((response) => {
        //         this.loading = false;
        //         //this.rewardsDetails = response;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.loading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // addPaymentMethod(params = {}, loader = false) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     API.post(ENDPOINTS.EP_ADD_PAYMENT_METHOD, params)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // deletePaymentMethod(params = {}, loader = false,id) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     let ep = ENDPOINTS.EP_DELETE_PAYMENT_METHOD;
        //     ep = ep.replace("{{ID}}", id);
        //     API.DELETE(ep, params)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         this.subscriptionCards = this.subscriptionCards.filter((val)=> val.id != id )
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        /*** Subscription - End ***/
        /*** Rewards - Start ***/
        getRewardsDetails: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_REWARDS_DETAILS)
                    .then(function (response) {
                    _this.loading = false;
                    _this.rewardsDetails = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getWithdrawalHistory: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_WITHDRAWAL_HISTORY)
                    .then(function (response) {
                    _this.loading = false;
                    _this.withdrawalHistory = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getRewardsHistory: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_REWARDS_HISTORY)
                    .then(function (response) {
                    _this.loading = false;
                    _this.rewardsHistory = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getWithdrawalFunds: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_WITHDRAWAL_FUNDS)
                    .then(function (response) {
                    _this.loading = false;
                    _this.withdrawalfunds = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getWithdrawalPayment: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_REQUEST_PAYMENT, params)
                    .then(function (response) {
                    if (response) {
                        toast("Withdrawal request has been submitted.", tostTypeSuccess);
                    }
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getExistingPayment: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_EXISTING_PAYMENT)
                    .then(function (response) {
                    _this.loading = false;
                    _this.existingPayment = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        cancelWithdrawalRequest: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                API.put(ENDPOINTS.EP_CANCEL_REQUEST, params).then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        /*** Rewards - End ***/
        /*** Trading Accounts - Start ***/
        accountsBecomeLeader: function (params, loader, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.customSpinner = true;
                }
                var ep = ENDPOINTS.EP_BECOME_LEADER;
                if (_that.importHistory) {
                    ep = ep.concat("&importHistory=true");
                }
                else {
                    ep = ep.concat("&importHistory=false");
                }
                API.put(ep, params).then(function (response) {
                    if (response) {
                        toast("Your strategy will be reviewed and you will become a leader after the approval", tostTypeSuccess);
                    }
                    _that.customSpinner = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.customSpinner = false;
                    reject(error);
                });
            });
        },
        LeaderAccImportHistory: function (params, loader, providerId, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.historyLoading = true;
                }
                var ep = ENDPOINTS.EP_LEADER_IMPORT_HISTORY;
                if (providerId) {
                    ep = ep.replace("{id}", providerId);
                }
                API.put(ep, params).then(function (response) {
                    _that.historyLoading = false;
                    if (response === true) {
                        toast("Your Resquest has been submitted successfully", tostTypeSuccess);
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.historyLoading = false;
                    reject(error);
                });
            });
        },
        getProviderTitleDesc: function (params, loader, id, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.tradesLoading = true;
                }
                var ep = ENDPOINTS.EP_GET_PROVIDER_ACCOUNT_DETAIL;
                ep = ep.replace("{{id}}", id);
                API.get(ep).then(function (response) {
                    _that.tradesLoading = false;
                    _this.tradingAccountTitleDesc = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getBrokerAccountDetail: function (params, loader, BROKER_ID) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.tradesLoading = true;
                }
                var ep = ENDPOINTS.EP_GET_BROKER_ACCOUNT_DETAIL;
                ep = ep.replace("{{BROKER_ID}}", BROKER_ID);
                API.get(ep).then(function (response) {
                    _this.tradesLoading = false;
                    _this.brokerAccountDetail = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        postBrokerAccountDetail: function (params, loader, BROKER_ID, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.customSpinnerr = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_POST_BROKER_ACCOUNT_DETAIL;
                ep = ep.replace("{{BROKER_ID}}", BROKER_ID);
                API.put(ep, params).then(function (response) {
                    _that.customSpinnerr = false;
                    toast("Account settings updated", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.customSpinnerr = false;
                    reject(error);
                });
            });
        },
        updateBrokerDesc: function (params, loader, _that, stat) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.customSpinner = true;
            }
            return new Promise(function (resolve, reject) {
                API.put(ENDPOINTS.EP_UPDATE_DESC, params).then(function (response) {
                    _that.customSpinner = false;
                    if (stat == 'rejected') {
                        toast("Your strategy will be reviewed and you will become a leader after the approval", tostTypeSuccess);
                    }
                    else {
                        toast("Pending strategy description update approval", tostTypeSuccess);
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.customSpinner = false;
                    reject(error);
                });
            });
        },
        /*** Trading Accounts - End ***/
        /*** Dashboad portfolio - Start ***/
        GetCopyTraders: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (typeof this.user.access_token === 'undefined') {
                return;
            }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                API.get(ENDPOINTS.EP_PROVIDER_LIST).then(function (response) {
                    _this.loading = false;
                    _this.copyTraders = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getProviderOpenTrades: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (typeof this.user.access_token === 'undefined') {
                return;
            }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.tradesLoading = true;
                }
                var ep = ENDPOINTS.EP_PROVIDER_OPEN_TRADES;
                if (_this.allTypeAccount) {
                    _this.userSelectedAccount = {};
                    if (_this.allTypeAccount == 'ALL_DEMO') {
                        ep = ep.concat("demo=true&");
                    }
                    else if (_this.allTypeAccount == 'ALL_LIVE') {
                        ep = ep.concat("demo=false&");
                    }
                }
                if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId + "&");
                }
                ep = ep.concat("calculateProfit=true");
                API.get(ep).then(function (response) {
                    _this.tradesLoading = false;
                    _this.providerOpenTrades = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getProviderPendingTrades: function (params, loader, id, providerId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.tradesLoading = true;
                }
                var ep = ENDPOINTS.EP_PROVIDER_PENDING_TRADES;
                ep = ep.replace("{{page}}", params["page"]);
                ep = ep.replace("{{size}}", params["size"]);
                ep = ep.replace("{{ID}}", id);
                if (providerId && providerId != -1) {
                    ep = ep.concat("&providerId=" + providerId);
                }
                API.get(ep).then(function (response) {
                    _this.tradesLoading = false;
                    _this.providerPendingTrades = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getProviderHistoryTrades: function (params, loader, id, providerId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.tradesLoading = true;
                }
                var ep = ENDPOINTS.EP_PROVIDER_HISTORY_TRADES;
                ep = ep.replace("{{page}}", params["page"]);
                ep = ep.replace("{{size}}", params["size"]);
                ep = ep.replace("{{ID}}", id);
                if (providerId && providerId != -1) {
                    ep = ep.concat("&providerId=" + providerId);
                }
                API.get(ep).then(function (response) {
                    _this.tradesLoading = false;
                    _this.providerHistoryTrades = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getFullDetailOpenTrade: function (params, loader, id, _that, targetBrokerAccountId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.tradeLoader = true;
                }
                var ep = ENDPOINTS.EP_OPEN_TRADE_DETAILS;
                ep = ep.replace("{{BROKER_TICKET}}", id);
                ep = ep.replace("{{targetBrokerAccountId}}", targetBrokerAccountId);
                API.get(ep).then(function (response) {
                    _that.tradeLoader = false;
                    _this.openTradeFullDetail = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.tradeLoader = false;
                    reject(error);
                });
            });
        },
        getFullDetailPendingTrade: function (params, loader, id, _that, targetBrokerAccountId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.tradeLoader = true;
                }
                var ep = ENDPOINTS.EP_PENDING_ORDER_DETAILS;
                ep = ep.replace("{{BROKER_TICKET}}", id);
                ep = ep.replace("{{targetBrokerAccountId}}", targetBrokerAccountId);
                API.get(ep).then(function (response) {
                    _that.tradeLoader = false;
                    _this.openTradeFullDetail = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.tradeLoader = false;
                    reject(error);
                });
            });
        },
        getFullDetailClosedTrade: function (params, loader, id, _that, targetBrokerAccountId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _that.tradeLoader = true;
                }
                var ep = ENDPOINTS.EP_CLOSED_TRADE_DETAILS;
                ep = ep.replace("{{BROKER_TICKET}}", id);
                ep = ep.replace("{{targetBrokerAccountId}}", targetBrokerAccountId);
                API.get(ep).then(function (response) {
                    _that.tradeLoader = false;
                    _this.openTradeFullDetail = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.tradeLoader = false;
                    reject(error);
                });
            });
        },
        changePassword: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                API.put(ENDPOINTS.EP_CHANGE_PASSWORD, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    toast("Password changed successfully", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        /*** Dashboad portfolio - End ***/
        callStrategyCurrencies: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                var inputjson = JSON.parse(JSON.stringify(params));
                if (loader) {
                    if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                        _this.singleLoading = true;
                    }
                    else {
                        _this.loading = true;
                    }
                }
                // EP_GET_STRATEGY_WISE_CURRENCY
                API.post(ENDPOINTS.EP_GET_ALL_CURRENCY_CATEGORIES, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.singleLoading = false;
                    if (inputjson["sortingStrategy"]) {
                        if (inputjson["sortingStrategy"] == "TOP_WINNERS") {
                            if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                                if (Object.keys(response.elements).length) {
                                    Object.values(response.elements).forEach(function (i) {
                                        if (i["hourlyChart"] && i["hourlyChart"].length) {
                                            i["hourlyChart"].forEach(function (val) {
                                                if (val.datetime) {
                                                    val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                                }
                                            });
                                        }
                                    });
                                    _this.topWinnersList.elements = __assign(__assign({}, _this.topWinnersList.elements), response.elements);
                                    _this.topWinnersList.lastIncluded = response.lastIncluded;
                                }
                                else {
                                    _this.topWinnersList = response;
                                }
                            }
                            else {
                                if (Object.keys(response.elements).length) {
                                    Object.values(response.elements).forEach(function (i) {
                                        if (i["hourlyChart"] && i["hourlyChart"].length) {
                                            i["hourlyChart"].forEach(function (val) {
                                                if (val.datetime) {
                                                    val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                                }
                                            });
                                        }
                                    });
                                    _this.topWinnersList = response;
                                }
                                else {
                                    _this.topWinnersList = response;
                                }
                            }
                        }
                        if (inputjson["sortingStrategy"] == "TOP_LOSERS") {
                            if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                                if (Object.keys(response.elements).length) {
                                    Object.values(response.elements).forEach(function (i) {
                                        if (i["hourlyChart"] && i["hourlyChart"].length) {
                                            i["hourlyChart"].forEach(function (val) {
                                                if (val.datetime) {
                                                    val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                                }
                                            });
                                        }
                                    });
                                    _this.topLoosersList.elements = __assign(__assign({}, _this.topLoosersList.elements), response.elements);
                                    _this.topLoosersList.lastIncluded = response.lastIncluded;
                                }
                                else {
                                    _this.topLoosersList = response;
                                }
                            }
                            else {
                                if (Object.keys(response.elements).length) {
                                    Object.values(response.elements).forEach(function (i) {
                                        if (i["hourlyChart"] && i["hourlyChart"].length) {
                                            i["hourlyChart"].forEach(function (val) {
                                                if (val.datetime) {
                                                    val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                                }
                                            });
                                        }
                                    });
                                    _this.topLoosersList = response;
                                }
                                else {
                                    _this.topLoosersList = response;
                                }
                            }
                        }
                        if (inputjson["sortingStrategy"] == "MOST_POPULAR") {
                            if (inputjson["page"] && parseInt(inputjson["page"]) > 1) {
                                if (Object.keys(response.elements).length) {
                                    Object.values(response.elements).forEach(function (i) {
                                        if (i["hourlyChart"] && i["hourlyChart"].length) {
                                            i["hourlyChart"].forEach(function (val) {
                                                if (val.datetime) {
                                                    val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                                }
                                            });
                                        }
                                    });
                                    _this.mostPolularCurrencyList.elements = __assign(__assign({}, _this.mostPolularCurrencyList.elements), response.elements);
                                    _this.mostPolularCurrencyList.lastIncluded = response.lastIncluded;
                                }
                                else {
                                    _this.mostPolularCurrencyList = response;
                                }
                            }
                            else {
                                if (Object.keys(response.elements).length) {
                                    Object.values(response.elements).forEach(function (i) {
                                        if (i["hourlyChart"] && i["hourlyChart"].length) {
                                            i["hourlyChart"].forEach(function (val) {
                                                if (val.datetime) {
                                                    val.datetime = new Date(moment(val.datetime[0] + "-" + val.datetime[1] + "-" + val.datetime[2] + "-" + val.datetime[3] + "-" + val.datetime[4], "YYYY-MM-DD HH:mm").toISOString()).getTime();
                                                }
                                            });
                                        }
                                    });
                                    _this.mostPolularCurrencyList = response;
                                }
                                else {
                                    _this.mostPolularCurrencyList = response;
                                }
                            }
                        }
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getAssetDetail: function (params, loader, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ASSET_DETAILS;
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("?targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("?targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API.post(ep, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    if (Object.values(response).length) {
                        _this.assetDetail = Object.values(response)[0];
                    }
                    else {
                        _this.assetDetail = response;
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        getAssetTrades: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ASSET_TRADES;
                if (id) {
                    ep = ep.replace('{{cid}}', id);
                }
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("&targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("&targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.assetTradesList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getCurrenyNews: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_GET_ASSET_NEWS, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.assetNewsList = response.data;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getMarketNews: function (params, loader, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.loaderNews = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_GET_MARKET_NEWS, params)
                    .then(function (response) {
                    _that.loaderNews = false;
                    if (params['page'] > 1) {
                        _this.marketNews.records = _this.marketNews.records.concat(response.data.records);
                    }
                    else {
                        _this.marketNews = response.data;
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.loaderNews = false;
                    reject(error);
                });
            });
        },
        getAssetPendingTrades: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ASSET_PENDING_TRADES;
                if (id) {
                    ep = ep.replace('{{cid}}', id);
                }
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("&targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("&targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.assetPendindTradesList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getAssetClosedTrades: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ASSET_CLOSED_TRADES;
                if (id) {
                    ep = ep.replace('{{cid}}', id);
                }
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("&targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("&targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.assetTradesHistoryList = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getCurrencywithID: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.EP_GET_CURRENCY_WITH_ID)
                    .then(function (response) {
                    _this.loading = false;
                    _this.assetListWithID = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getConverstionStats: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_CONVESRTION_STATS;
                if (id) {
                    ep = ep.replace('{{ID}}', id);
                }
                API.post(ep, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.assetTradingConvesrionData = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        placeMarketOrder: function (params, loader, id, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.loader = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_ASSET_PLACE_ORDER;
                if (id) {
                    ep = ep.replace('{{ID}}', id);
                }
                API.post(ep, params)
                    .then(function (response) {
                    _that.loader = false;
                    // toast("Trade has been place successfully", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.loader = false;
                    reject(error);
                });
            });
        },
        placePendingOrder: function (params, loader, id, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.loader = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_ASSET_PENDING_ORDER;
                if (id) {
                    ep = ep.replace('{{ID}}', id);
                }
                API.post(ep, params)
                    .then(function (response) {
                    _that.loader = false;
                    //  toast("Trade has been place successfully", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.loader = false;
                    reject(error);
                });
            });
        },
        closeOpenOrder: function (params, loader, id, targetBrokerAccountId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_CLOSE_OPEN_ORDER;
                if (id) {
                    ep = ep.replace('{{ID}}', id);
                    ep = ep.replace('{{targetBrokerAccountId}}', targetBrokerAccountId);
                }
                API.post(ep, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    // toast("Trade has been closed successfully", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        closePendingOpenOrder: function (params, loader, id, targetBrokerAccountId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_CLOSE_PENDING_ORDER;
                if (id) {
                    ep = ep.replace('{{ID}}', id);
                    ep = ep.replace('{{targetBrokerAccountId}}', targetBrokerAccountId);
                }
                API.put(ep, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    // toast("Trade has been closed successfully", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getCopyStats: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_TRADER_COPY_STATS;
                ep = ep.replace('{{ID}}', id);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.leaderCopyInfo = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        copyLeader: function (params, loader, ID, openTrade, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_COPY_LEADER;
                ep = ep.replace('{{ID}}', ID);
                if (openTrade) {
                    ep += '&openTrades=true';
                }
                // if(this.userSelectedAccount.isLeader && this.userSelectedAccount.providerStatus=='APPROVED'){
                //   toast("You can't copy any leader", tostTypeInfo);
                // } else {
                API.post(ep, params)
                    .then(function (response) {
                    _that.tradesLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.tradesLoading = false;
                    // toast(error.message, tostTypeInfo);
                    reject(error);
                });
                // }
            });
        },
        updateLeader: function (params, loader, ID, openTrade, _that) {
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_UPDATE_COPY_STATEGY;
                ep = ep.replace('{{ID}}', ID);
                if (openTrade) {
                    ep += '&openTrades=true';
                }
                // if(this.userSelectedAccount.isLeader && this.userSelectedAccount.providerStatus=='APPROVED'){
                //   toast("You can't copy any leader", tostTypeInfo);
                // } else {
                API.put(ep, params)
                    .then(function (response) {
                    _that.tradesLoading = false;
                    // toast("Update Strategy Successfully ", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.tradesLoading = false;
                    reject(error);
                });
                // }
            });
        },
        stopStategy: function (params, loader, LeaderID, ID, type, ans, _that) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                _that.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_DELETE_COPY_STATEGY;
                ep = ep.replace('{{ID}}', ID);
                ep = ep.replace('{{LEADER_ID}}', LeaderID);
                ep = ep.replace('{{ans}}', ans == 'yes' ? 'true' : 'false');
                API.DELETE(ep, params)
                    .then(function (response) {
                    _that.tradesLoading = false;
                    _this.leaderCopyInfo = {};
                    if (type == 'removeStrategy') {
                        toast("Removed Successfully", tostTypeSuccess);
                    }
                    else {
                        // toast("Stop Strategy Successfully ", tostTypeSuccess);
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _that.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getMarketCloseDays: function (params, loader, asset) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_MARKET_CLOSE_DAYS;
                ep = ep.replace('{{ASSET}}', asset);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.marketClosedDays = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        callassetSearchList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = false;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_ASSET_SEARCH_LIST;
                API.post(ep, params)
                    .then(function (response) {
                    _this.assetSearchList = response;
                    _this.tradesLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        getUserDashboardData: function (params, loader, change, timeframe) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            if (typeof this.user.access_token === "undefined") {
                return;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_USER_DASHBOARD_DATA;
                if (_this.allTypeAccount) {
                    if (change == 'no-change') {
                        _this.userSelectedAccount = {};
                    }
                    if (_this.allTypeAccount == 'ALL_DEMO') {
                        ep = ep.concat("demo=true&");
                    }
                    else if (_this.allTypeAccount == 'ALL_LIVE') {
                        ep = ep.concat("demo=false&");
                    }
                }
                if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("targetZuluAccountId=" + _this.userSelectedAccount.zuluAccountId + "&");
                }
                ep = ep.concat("timeframe=" + timeframe);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.userDashboardDetail = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        getAllCurrencySymbols: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = false;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ALL_BASECURRENCY;
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.allCurrencySymbols = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getUserChartWithLeader: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_USER_CHART_WITH_LEADER;
                if (_this.allTypeAccount) {
                    if (_this.allTypeAccount == 'ALL_DEMO') {
                        ep = ep.concat("demo=true&");
                    }
                    else if (_this.allTypeAccount == 'ALL_LIVE') {
                        ep = ep.concat("demo=false&");
                    }
                }
                if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId + "&");
                }
                ep = ep.concat("pid=" + params['pid'] + "&");
                ep = ep.concat("type=" + params['type'] + "&");
                ep = ep.concat("timespan=" + params['timespan'] + "&");
                ep = ep.concat("timeframe=" + params['timeframe']);
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.userChartWithLeader = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        userNotifications: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            if (typeof this.user.access_token === "undefined") {
                return;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_ALL_NOTIFICATIONS;
                var queries = [];
                for (var key in params) {
                    queries.push(key + "=" + params[key]);
                }
                ep += queries.join('&') || '';
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    if (!params['dateTo']) {
                        _this.usertopNotifications = response;
                    }
                    else {
                        _this.userNotificationList = response;
                        // if(params['size'] > 10){
                        //   if (Object.keys(this.userNotificationList).length && this.userNotificationList.content && this.userNotificationList.content.length) {
                        //     this.userNotificationList.content = this.userNotificationList.content.concat(response.content);
                        //   }else{
                        //     this.userNotificationList = response
                        //   }
                        // }else{
                        //   this.userNotificationList = response
                        // }
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        updateSTOPLOSS: function (params, loader, ticket, targetBrokerAccountId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_UPDATE_SL;
                ep = ep.replace('{{TICKET}}', ticket);
                ep = ep.replace('{{ID}}', targetBrokerAccountId);
                API.put(ep, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.getProviderOpenTrades({}, false);
                    // toast("Stop Loss has updated successfully", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        updateTAKEPROFIT: function (params, loader, ticket, targetBrokerAccountId) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_UPDATE_TP;
                ep = ep.replace('{{TICKET}}', ticket);
                ep = ep.replace('{{ID}}', targetBrokerAccountId);
                API.put(ep, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    _this.getProviderOpenTrades({}, false);
                    // toast("Take Profit has updated successfully", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        /* Calendar Start */
        getCalendar: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                API.post(ENDPOINTS.EP_CALENDAR, params).then(function (response) {
                    // const original = JSON.parse(JSON.stringify(response))
                    var original = response.data;
                    var temp = [];
                    original.map(function (i, index) {
                        var ind = temp.findIndex(function (j) { return moment(j.date).format('YYYY-MM-DD') == moment(i.date).format('YYYY-MM-DD'); });
                        if (ind > -1) {
                            if (temp[ind].items) {
                                temp[ind].items.push(i);
                            }
                            else {
                                temp[ind].items = [i];
                            }
                        }
                        else {
                            temp.push({ date: moment(i.date).format('YYYY-MM-DD'), items: [i] });
                        }
                    });
                    _this.loading = false;
                    _this.calendarData = temp;
                    resolve(response);
                }).catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getCalendarTickerHistory: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.tradesLoading = true;
                }
                API.post(ENDPOINTS.EP_CALENDAR_TICKER_HISTORY, params).then(function (response) {
                    _this.tradesLoading = false;
                    if (response.data.chartdata && response.data.chartdata.length) {
                        response.data.chartdata.map(function (i) {
                            i.date = new Date(i.date).getTime();
                        });
                    }
                    _this.calendarTickerData = response.data;
                    resolve(response);
                }).catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        /* Calendar End */
        /* Feedback Start */
        postFeedback: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.tradesLoading = false;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_FEEDBACK;
                API.post(ep, params)
                    .then(function (response) {
                    _this.tradesLoading = false;
                    toast("Thankyou for submitting your feedback", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.tradesLoading = false;
                    reject(error);
                });
            });
        },
        /* Feedback End */
        getFlavorID: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = false;
            }
            return new Promise(function (resolve, reject) {
                var ep = (_this.user.access_token) ? ENDPOINTS.EP_GET_LOGIN_FLAVOR_LIST : ENDPOINTS.EP_GET_FLAVOR_LIST;
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    _this.flavordata = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getBlogsData: function (params, loader, id, limit, page) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.EP_GET_BLOG_CATEGORY_WISE_DATA;
                ep = ep.replace('{{category}}', id);
                ep = ep.replace('{{limit}}', limit);
                ep = ep.replace('{{page}}', page);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getBlogDetail: function (params, loader, slug) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = (slug.includes('-')) ? ENDPOINTS.EP_GET_BLOG_DETAIL : ENDPOINTS.EP_GET_BLOG_DETAIL_ID;
                ep = ep.replace('{{slug}}', slug);
                API.get(ep)
                    .then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        brokerRaiseQuery: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_BROKER_RAISE_QUERY, params)
                    .then(function (response) {
                    _this.loading = false;
                    toast("Thank you for your submission. We will add your broker shortly", tostTypeSuccess);
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        affilateLanded: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.post(ENDPOINTS.EP_AFFILATE_LANDED, params)
                    .then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        // Notification settings
        notificationSettings: function (params, loader, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.NOTIFICATION_SETTING;
                if (_this.allTypeAccount) {
                    var data = [];
                    if (_this.allTypeAccount == 'ALL_DEMO') {
                        data = _this.userTradingAccountsList.filter(function (i) { return i.demo === true; });
                    }
                    else if (_this.allTypeAccount == 'ALL_LIVE') {
                        data = _this.userTradingAccountsList.filter(function (i) { return i.demo === false; });
                    }
                    if (data.length) {
                        ep = ep.concat("?targetZuluAccountId=" + data[0].zuluAccountId);
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("?targetZuluAccountId=" + _this.userSelectedAccount.zuluAccountId);
                }
                API[type == 'get' ? 'get' : 'put'](ep, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        // Account settings
        accountSettings: function (params, loader, type) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.MONITOR_ACCOUNT_SETTING;
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("?targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("?targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API[type == 'get' ? 'get' : 'post'](ep, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.brokerAccountSettings = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        newsletterSettings: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.NEWSLETTER_SETTING;
                var queries = [];
                for (var key in params) {
                    queries.push(key + "=" + params[key]);
                }
                ep += queries.join('&') || '';
                API.put(ep, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        // Close All Position's
        closeAllPositions: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.put(ENDPOINTS.CLOSE_ALL_TRADES, params)
                    .then(function (response) {
                    _this.loading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        // Cancelled Order Log
        cancelledOrderLog: function (params, loader, id) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.CANCELLED_ORDERS_LOG;
                var queries = [];
                for (var key in params) {
                    queries.push(key + "=" + params[key]);
                }
                ep += queries.join('&') || '';
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("&targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("&targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        // Trade History in Dashboard
        tradeHistory: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.TRADE_HISTORY;
                var queries = [];
                for (var key in params) {
                    queries.push(key + "=" + params[key]);
                }
                ep += queries.join('&') || '';
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("&targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("&targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    _this.dashboardTradesHistory = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        // Trade History chart in Dashboard
        tradeHistoryChart: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.TRADE_HISTORY_CHART;
                var queries = [];
                for (var key in params) {
                    queries.push(key + "=" + params[key]);
                }
                ep += queries.join('&') || '';
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("&targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("&targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        // Trade History Leaders in Dashboard
        tradeHistoryLeaders: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.TRADE_HISTORY_LEADERS;
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.replace("{{brokerID}}", _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.replace("{{brokerID}}", _this.userSelectedAccount.brokerAccountId);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        // Trade History Currency-Pair in Dashboard
        tradeHistoryCurrency: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.TRADE_HISTORY_CURRENCY;
                var queries = [];
                for (var key in params) {
                    queries.push(key + "=" + params[key]);
                }
                ep += queries.join('&') || '';
                if (_this.allTypeAccount) {
                    if (_this.getBrokerAccountID() !== null) {
                        ep = ep.concat("&targetBrokerAccountId=" + _this.getBrokerAccountID());
                    }
                }
                else if (Object.keys(_this.userSelectedAccount).length) {
                    ep = ep.concat("&targetBrokerAccountId=" + _this.userSelectedAccount.brokerAccountId);
                }
                API.get(ep)
                    .then(function (response) {
                    _this.singleLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        // Get payment type
        getPaymentType: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = false;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.GET_PAYMENT_TYPE;
                API.get(ep)
                    .then(function (response) {
                    var _a;
                    _this.loading = false;
                    if (response === null || response === void 0 ? void 0 : response.length) {
                        _this.priorityPayment = ((_a = response.find(function (i) { return i.priority == 1; })) === null || _a === void 0 ? void 0 : _a.psp) || 'CHECKOUT';
                    }
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        // Make New Payment
        // unlimitNewPayment(params = {}, loader = false) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     const ep = ENDPOINTS.UNLIMIT_NEW_PAYMENT;
        //     API.post(ep,params)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // Make Recurring Payment
        // unlimitRecurringPayment(params = {}, loader = false) {
        //   if (loader) { this.singleLoading = true; }
        //   return new Promise((resolve, reject) => {
        //     const ep = ENDPOINTS.UNLIMIT_RECURRING_PAYMENT;
        //     API.post(ep,params)
        //       .then((response) => {
        //         this.singleLoading = false;
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.singleLoading = false;
        //         reject(error);
        //       });
        //   });
        // },
        // Get Unlimit instrument
        // getUnlimitSavedCards(params = {}, loader = false) {
        //   if (loader) { this.loading = false; }
        //   return new Promise((resolve, reject) => {
        //     const ep = ENDPOINTS.UNLIMIT_INSTRUMENT;
        //     API.get(ep)
        //       .then((response) => {
        //         this.loading = false;
        //         this.unlimitSavedCards = response
        //         resolve(response);
        //       })
        //       .catch((error) => {
        //         this.loading = false;
        //         reject(error);
        //       });
        //   });
        // },
        getQuestioanriesStatus: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.loading = true;
            }
            return new Promise(function (resolve, reject) {
                API.get(ENDPOINTS.GET_QUESTIONARIES_STATUS, params)
                    .then(function (response) {
                    _this.loading = false;
                    _this.questionariesStatus = response;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        getBrokerStabilityQuestionsList: function (params, loader) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            return new Promise(function (resolve, reject) {
                if (loader) {
                    _this.loading = true;
                }
                API.get(ENDPOINTS.EP_GET_BROKER_STABILITY_QUESTIONS_LIST).then(function (response) {
                    _this.loading = false;
                    if (Object.keys(response).length > 0 && response[0].questions && response[0].questions.length) {
                        response[0].questions = response[0].questions.filter(function (val) { return (val.frontend === true && val.status === true); });
                        _this.sutabilityQuestionsList = response;
                    }
                    else {
                        _this.sutabilityQuestionsList = response;
                    }
                    resolve(response);
                }).catch(function (error) {
                    _this.loading = false;
                    reject(error);
                });
            });
        },
        // Pro-Rata
        prorata: function (params, loader, ID) {
            var _this = this;
            if (params === void 0) { params = {}; }
            if (loader === void 0) { loader = false; }
            if (loader) {
                this.singleLoading = true;
            }
            return new Promise(function (resolve, reject) {
                var ep = ENDPOINTS.PRORATA;
                ep = ep.replace('{{ID}}', ID);
                API.post(ep, params)
                    .then(function (response) {
                    _this.singleLoading = false;
                    resolve(response);
                })
                    .catch(function (error) {
                    _this.singleLoading = false;
                    reject(error);
                });
            });
        },
        getBrokerAccountID: function () {
            if (this.allTypeAccount === 'ALL_DEMO') {
                var demoAccount = this.userTradingAccountsList.find(function (account) { return account.demo === true; });
                return demoAccount ? demoAccount.brokerAccountId : null;
            }
            else if (this.allTypeAccount === 'ALL_LIVE') {
                var liveConnectedAccount = this.userTradingAccountsList.find(function (account) { return account.demo === false && account.connectivityStatus === 'Connected'; });
                return liveConnectedAccount ? liveConnectedAccount.brokerAccountId : null;
            }
            return null;
        },
        updateBrokerTickets: function (tickets) {
            this.brokerTicketsList = __spreadArrays(this.brokerTicketsList, tickets);
        },
        reset: function () {
            this.isLogin = true;
            this.logout();
        },
        logout: function () {
            //code used to solve problem of autologin when mutiple tabs 
            if (this.isLogin == true) {
                var broadcastChannel = new BroadcastChannel('zuluchannel');
                broadcastChannel.postMessage('ExecuteFunction');
                this.isLogin = false;
            }
            this.loading = true;
            if (localStorage.getItem("zulutoken")) {
                localStorage.removeItem("zulutoken");
            }
            if (localStorage.getItem("zulurefreshToken")) {
                localStorage.removeItem("zulurefreshToken");
            }
            // store.$reset()
            // this.user = {}
            // this.userSelectedAccount = {}
            LoginFeed.getInstance().deactivate();
            // Message.getInstance().deactivate();
            PriceFeed.getInstance().activate();
            if (window.localStorage.storekey) {
                var data = JSON.parse(window.localStorage.storekey);
                for (var key in data) {
                    if (key == 'brokerPlatformList' || key == 'brokerSymbolPattern' || key == 'stabilityQuestionsList' || key == 'getBrokerTradingAccList') {
                        data[key] = [];
                    }
                    else {
                        data[key] = {};
                    }
                }
                window.localStorage.setItem("storekey", JSON.stringify(data));
            }
            var activepinia = getActivePinia();
            if (activepinia) {
                Object.entries(activepinia.state.value).forEach(function (_a) {
                    var storeName = _a[0], state = _a[1];
                    var storeDefinition = defineStore(storeName, state);
                    var store = storeDefinition(activepinia);
                    store.$reset();
                });
            }
            router.replace("/");
            this.loading = false;
        }
    }
});
